import './Companies.scss';
import { useState, useMemo } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { IoIosAdd, IoMdPeople } from 'react-icons/io';
import { SweetAlertResult } from 'sweetalert2';
import { toast } from 'react-hot-toast';
import { AxiosError } from 'axios';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import {
  getCoreRowModel,
  useReactTable,
  PaginationState,
  ColumnDef,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  ColumnFiltersState,
  Table as ReactTable,
} from '@tanstack/react-table';
import ContentHeader from '../../components/UI/ContentHeader/ContentHeader';
import TableActions from '../../components/UI/TableActions/TableActions';
import CompanyService from '../../services/CompanyService';
import { ReactQueryKeys } from '../../constants/react-query-keys';
import { Company } from '../../interfaces/company';
import SwalAlert, {
  firePreConfirmAlert,
} from '../../components/UI/SwalAlert/SwalAlert';
import Pagination from '../../components/UI/Pagination/Pagination';
import DebouncedInput from '../../components/UI/DebouncedInput/DebouncedInput';
import Table from '../../components/UI/Table/Table';
import { useTranslation } from 'react-i18next';

const STATUS = ['enabled', 'disabled'];

const Companies = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  /**
   * Define columns for the table
   */
  const columns = useMemo<ColumnDef<Company>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('companies.name'),
        enableSorting: true,
        enableColumnFilter: true,
        cell: (info) => (
          <div className="table-actions-wrapper">
            <Link
              to={`/users?company_id=${info.row.original.id}`}
            >{`${info.getValue()}`}</Link>
          </div>
        ),
      },
      {
        id: 'disabled_at',
        accessorFn: (row) =>
          row.disabled_at ? t('labels.disabled') : t('labels.enabled'),
        header: t('companies.status'),
        enableSorting: true,
        enableColumnFilter: true,
        cell: (info) => (
          <div className="table-actions-wrapper">
            <div>
              {info.row.original.disabled_at
                ? t('labels.disabled')
                : t('labels.enabled')}
            </div>
            <TableActions
              buttons={
                <>
                  <Link
                    className="table-actions-edit"
                    to={`/users?company_id=${info.row.original.id}`}
                  >
                    <span>
                      <IoMdPeople style={{ color: '#fff' }} size={18} />
                    </span>
                  </Link>
                </>
              }
              editLink={`/companies/${info.row.original.id}`}
              onDisable={
                !info.row.original.disabled_at
                  ? () => handleTableDisableShow(info.row.original)
                  : null
              }
              onDelete={
                info.row.original.disabled_at
                  ? () => handleTableDeleteShow(info.row.original)
                  : null
              }
            />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const dataQuery = useQuery(
    [
      ReactQueryKeys.COMPANIES,
      {
        pageIndex,
        pageSize,
        sorting,
        columnFilters,
      },
    ],
    () =>
      CompanyService.get({
        pageIndex,
        pageSize,
        sorting,
        columnFilters,
      }),
    { keepPreviousData: true },
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  // Show alert for confirming of disabling a company
  const handleTableDisableShow = (company: Company) => {
    firePreConfirmAlert({
      title: t('companies.messages.modal__disable__title'),
      html: t('companies.messages.modal__disable__content', {
        name: company.name,
      }),
      preConfirm: () => {
        return CompanyService.disable(company.id!)
          .then(({ data }) => {
            return data;
          })
          .catch((error: AxiosError) => {
            // @ts-ignore
            SwalAlert.showValidationMessage(error?.response?.data?.message);
          });
      },
    }).then((data: SweetAlertResult) => {
      if (data.isConfirmed) {
        queryClient.invalidateQueries([ReactQueryKeys.COMPANIES]);
        toast.success(t('companies.messages.disable__success'));
      }
    });
  };
  // Show alert for confirming of deletion a company
  const handleTableDeleteShow = (company: Company) => {
    firePreConfirmAlert({
      title: t('companies.messages.modal__delete__title'),
      html: t('companies.messages.modal__delete__content', {
        name: company.name,
      }),
      preConfirm: () => {
        return CompanyService.delete(company.id!)
          .then(({ data }) => {
            return data;
          })
          .catch((error: AxiosError) => {
            // @ts-ignore
            SwalAlert.showValidationMessage(error?.response?.data?.message);
          });
      },
    }).then((data: SweetAlertResult) => {
      if (data.isConfirmed) {
        queryClient.invalidateQueries([ReactQueryKeys.COMPANIES]);
        toast.success(t('companies.messages.delete__success'));
      }
    });
  };

  const table: ReactTable<Company> = useReactTable({
    data: dataQuery.data?.data.records ?? [],
    columns,
    pageCount: dataQuery?.data?.data.totalPages ?? -1,
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableMultiSort: false,
  });

  return (
    <>
      <ContentHeader
        title={t('companies.title')}
        actions={
          <>
            <span data-for="content-header-button" data-tip={t('buttons.add')}>
              <Link
                data-for="add-company"
                to="/companies/add"
                className="btn-rounded"
                data-testid="add-company"
              >
                <IoIosAdd size={40} />
              </Link>
            </span>
            <ReactTooltip
              id="content-header-button"
              place="bottom"
              effect="solid"
            />
          </>
        }
      />

      <Table
        classes="companies-table"
        isFetching={dataQuery.isFetching}
        table={table}
        filters={(header) => {
          return (
            <>
              {header.column.id === 'name' && (
                <DebouncedInput
                  value={(header.column.getFilterValue() ?? '') as string}
                  type="text"
                  data-testid="name-input"
                  onChange={(value) => header.column.setFilterValue(value)}
                  className="form-control form-control-sm rounded"
                />
              )}

              {/* Status filter */}
              {header.column.id === 'disabled_at' && (
                <Select
                  id="accessLevel"
                  maxMenuHeight={300}
                  onChange={header.column.setFilterValue}
                  value={header.column.getFilterValue()}
                  isMulti={false}
                  className="react-select-container-sm"
                  classNamePrefix="react-select"
                  aria-label="access_level"
                  isClearable
                  isSearchable
                  options={STATUS.map((status) => {
                    return {
                      // @ts-ignore
                      label: t(`labels.${status}`),
                      value: status,
                    };
                  })}
                />
              )}
            </>
          );
        }}
      />
      <Pagination table={table} />
      <Outlet />
    </>
  );
};

export default Companies;
