import './Sidebar.scss';
import { CognitoUser } from 'interfaces/user';
import { useSelector } from 'react-redux';
import { RolesEnum } from 'enums';
import SidebarLink from './SidebarLink';
// import { LANGUAGES } from 'config/i18n';
import { useTranslation } from 'react-i18next';
// import { I18n } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import { DEPLOYMENT_BASE_URL, FRONT_ROUTER } from 'constants/router';

const SuperAdminTopNavigation = () => {
  const { t } = useTranslation();
  return (
    <>
      <SidebarLink
        title={t('sidebar.companies')}
        link="/companies"
        icon="building"
        testid="super"
      />
      <SidebarLink title={t('sidebar.users')} link="/users" icon="users" />
    </>
  );
};

const RegularAdminTopNavigation = ({ pathname }: { pathname: string }) => {
  const { t } = useTranslation();
  const isHospitalActive = !![
    '/facilities',
    '/care-areas',
    '/drugs',
    '/advisories',
    '/therapies',
  ].filter((route) => !!pathname && pathname.includes(route)).length;

  return (
    <>
      <SidebarLink
        title={t('sidebar.hospital')}
        link="/facilities"
        icon="building"
        activeLink={isHospitalActive}
        testid="regular"
      />
      <SidebarLink title={t('sidebar.device')} link="/device" icon="device" />
      <SidebarLink
        title={t('sidebar.reports')}
        link="/reports"
        icon="reports"
      />
      <SidebarLink
        title={t('sidebar.deployment')}
        link={FRONT_ROUTER.DEPLOYMENT_FULL_DEPLOYMENT_TAB}
        icon="deployment"
        activeLink={pathname.includes(DEPLOYMENT_BASE_URL)}
      />
    </>
  );
};

const Sidebar = () => {
  // const { i18n } = useTranslation();
  const user: CognitoUser = useSelector((state: any) => state.auth.user);
  const isSuperAdmin = !!user && user?.roles?.includes(RolesEnum.SUPER_ADMIN);
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <nav className="nav-sidebar" data-testid="sidebar">
      <div className="nav-sidebar__links">
        {isSuperAdmin ? (
          <SuperAdminTopNavigation />
        ) : (
          <RegularAdminTopNavigation pathname={pathname} />
        )}
      </div>

      <div className="footer-sidebar">
        <SidebarLink
          title={t('sidebar.help')}
          link={FRONT_ROUTER.HELP}
          icon="help"
          testid="help-link"
        />
        {/* <div className="nav-sidebar__languages py-2">
          {Object.keys(LANGUAGES).map((lng: string) => {
            return (
              <button
                className="nav-sidebar__languages-btn"
                key={lng}
                onClick={() => {
                  i18n.changeLanguage(lng);
                  I18n.setLanguage(lng);
                }}
                disabled={i18n.resolvedLanguage === lng}
              >
                {LANGUAGES[lng as keyof typeof LANGUAGES]}
              </button>
            );
          })}
        </div> */}
      </div>
    </nav>
  );
};

export default Sidebar;
