import { FC, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { IoIosAdd, IoMdTrash } from 'react-icons/io';
import DebouncedInput from '../../../components/UI/DebouncedInput/DebouncedInput';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Advisory } from '../../../interfaces/advisory';
import { advisoryActions } from '../../../store/slices/advisory';
import { PaginationState } from '@tanstack/react-table';
import { AdvisoryResponse } from 'services/AdvisoryService';
import ListPagination from 'components/UI/ListPagination/ListPagination';
import ReactTooltip from 'react-tooltip';

type AdvisoryListProps = {
  advisoriesResponse: AdvisoryResponse | undefined;
  updateFilter: Function;
  onDelete: Function;
  canManage: boolean;
  setPagination: Function;
  pagination: PaginationState;
  refetch: boolean;
};

const AdvisoryList: FC<AdvisoryListProps> = ({
  advisoriesResponse,
  updateFilter,
  onDelete,
  canManage,
  pagination,
  setPagination,
  refetch,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const clearSearch: boolean = useSelector(
    (state: any) => state.advisory.clearSearch,
  );
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const isAdding = location.pathname.includes('/add');
    if (
      !params.id &&
      advisoriesResponse?.records.length &&
      !isAdding &&
      !refetch
    ) {
      navigate(`/advisories/${advisoriesResponse.records[0].id}`);
    }
  }, [advisoriesResponse, params.id, refetch, location.pathname, navigate]);

  const handleAddClick = () => {
    if (!advisoriesResponse?.records) return;

    const advisoryExists = advisoriesResponse.records.find(
      (advisory: Advisory) =>
        advisory.name.toLowerCase().trim() === search.toLowerCase().trim(),
    );

    if (advisoryExists) {
      toast.error(
        t('advisories.messages.name__exists', { name: advisoryExists.name }),
      );
      return false;
    }

    navigate({
      pathname: '/advisories/add',
      search: `?name=${search}`,
    });
  };

  useEffect(() => {
    if (clearSearch) {
      setSearch('');
      updateFilter('');
      dispatch(advisoryActions.resetClearSearch());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSearch]);

  return (
    <>
      <div className="master-list__search-wrapper">
        <DebouncedInput
          placeholder={t('advisories.search_box')}
          maxLength={20}
          value={search}
          type="text"
          onChange={(value) => {
            setSearch(value as string);
            updateFilter(value);
          }}
          data-testid="advisory_list_input"
          className="form-control form-control rounded mb-2"
        />

        {canManage ? (
          <>
            <button
              className="btn-rounded btn-rounded-sm master-list__button-add"
              onClick={handleAddClick}
              data-testid="add"
              data-tip
              data-for="advisory-add-tooltip"
            >
              <IoIosAdd size={40} />
            </button>
            <ReactTooltip
              id="advisory-add-tooltip"
              effect="solid"
              place="top"
              role="add_advisory_tooltip"
            >
              Add Advisory
            </ReactTooltip>
          </>
        ) : null}
      </div>

      {advisoriesResponse?.records.length === 0 && (
        <div className="alert mb-0">{t('messages.no_records')}</div>
      )}

      {advisoriesResponse && advisoriesResponse.records.length > 0 && (
        <div className="master-list master-list__no-scroll">
          {advisoriesResponse.records.map((advisory: Advisory) => {
            return (
              <NavLink
                to={`/advisories/${advisory.id}`}
                className="master-list__item"
                key={advisory.id}
              >
                {advisory.name}

                {canManage && !advisory.is_basic ? (
                  <>
                    <button
                      className="master-list__item-delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onDelete(advisory);
                      }}
                      data-testid="delete"
                      data-tip
                      data-for={`advisory-delete-tooltip-id-${advisory.id}`}
                    >
                      <IoMdTrash size={26} />
                    </button>
                    <ReactTooltip
                      id={`advisory-delete-tooltip-id-${advisory.id}`}
                      effect="solid"
                      place="top"
                      role={`delete-advisory-tooltip-id-${advisory.id}`}
                    >
                      Delete Advisory
                    </ReactTooltip>
                  </>
                ) : null}
              </NavLink>
            );
          })}
          <ListPagination
            canGetNextPage={
              Number(advisoriesResponse.pageIndex + 1) <
              Number(advisoriesResponse.totalPages)
            }
            canGetPreviousPage={Number(advisoriesResponse.pageIndex) > 0}
            nextPage={() => {
              setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex + 1,
              });
            }}
            previousPage={() => {
              setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex - 1,
              });
            }}
            pageCount={advisoriesResponse.totalPages}
            pageIndex={pagination.pageIndex}
            pageSize={pagination.pageSize}
            setPageSize={(pageSize: number) =>
              setPagination({
                ...pagination,
                pageSize,
              })
            }
            setPageIndex={(pageIndex: number) =>
              setPagination({
                ...pagination,
                pageIndex,
              })
            }
            hidePageSize
          />
        </div>
      )}
    </>
  );
};

export default AdvisoryList;
