import React, { useEffect, type FC, useRef } from 'react';
import './ListPagination.scss';
import { useTranslation } from 'react-i18next';

type ListPaginationProps = {
  setPageIndex: Function;
  setPageSize: Function;
  pageIndex: number;
  previousPage: Function;
  pageCount: number;
  pageSize: number;
  nextPage: Function;
  canGetPreviousPage: boolean;
  canGetNextPage: boolean;
  hidePageSize?: boolean;
};

const ListPagination: FC<ListPaginationProps> = ({
  setPageIndex,
  setPageSize,
  pageIndex,
  canGetPreviousPage,
  previousPage,
  pageCount,
  pageSize,
  canGetNextPage,
  nextPage,
  hidePageSize = false,
}) => {
  const { t } = useTranslation();

  const inputValue = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputValue.current) {
      inputValue.current.value = (pageIndex + 1).toString();
    }
  }, [pageIndex]);

  if (pageIndex < 0) {
    pageIndex = 0;
  }

  const preventMinus = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (['Minus', 'Period', 'Comma'].includes(event.code)) {
      event.preventDefault();
    }
  };

  const modifyTablePageState = (
    event:
      | React.FocusEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }

    const page = Number(event.target.value) - 1;

    if (page >= pageCount) {
      if (inputValue.current) {
        inputValue.current.value = (pageIndex + 1).toString();
      }

      return;
    }

    //if page is the same as the current page, do nothing
    if (pageIndex === page) return;

    //if blank reset input to defaultValue
    if ((event.target.value === '' || page < 0) && inputValue.current)
      return (inputValue.current.value = (pageIndex + 1).toString());

    if (page >= 0) {
      setPageIndex(page);
    }
  };

  const handlePageEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      modifyTablePageState(event);
    }
  };

  if (pageIndex < 0) {
    pageIndex = 0;
  }

  return (
    <div
      className="d-flex justify-content-between dl-list-pagination"
      data-testid="list-pagination-test-id"
    >
      <div className="dl-list-pagination__previous">
        <button onClick={() => previousPage()} disabled={!canGetPreviousPage}>
          {t('pagination.previous')}
        </button>
      </div>

      <div className="dl-list-pagination__center">
        {pageIndex >= 0 && pageCount > 0 && (
          <span className="dl-list-pagination__page-info">
            {t('pagination.go_to')}
            <div className="dl-list-pagination__page-jump">
              <input
                aria-label="jump to page"
                type="number"
                min={1}
                ref={inputValue}
                defaultValue={pageIndex + 1}
                onKeyDown={preventMinus}
                onKeyUp={handlePageEnter}
                onBlur={modifyTablePageState}
              />
            </div>
            <span className="dl-list-pagination__total-pages">
              {hidePageSize
                ? t('pagination.responsive.of') + pageCount
                : ' | ' +
                  t('pagination.page_number', {
                    value: pageIndex + 1,
                    total: pageCount,
                  })}
            </span>
          </span>
        )}
        {pageIndex >= 0 && pageCount > 0 && !hidePageSize && (
          <span className="dl-list-pagination__select-wrap">
            <select
              aria-label="rows per page"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setPageIndex(0);
              }}
            >
              {[2, 10, 20, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize} {t('pagination.rows')}
                </option>
              ))}
            </select>
          </span>
        )}
      </div>

      <div className="dl-list-pagination__next">
        <button onClick={() => nextPage()} disabled={!canGetNextPage}>
          {t('pagination.next')}
        </button>
      </div>
    </div>
  );
};

export default ListPagination;
