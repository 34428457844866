import { FC, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { IoIosAdd, IoMdTrash } from 'react-icons/io';
import DebouncedInput from '../../../components/UI/DebouncedInput/DebouncedInput';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { therapyActions } from '../../../store/slices/therapy';
import { Therapy } from '../../../interfaces/therapy';
import { TherapyResponse } from 'services/TherapyService';
import { PaginationState } from '@tanstack/react-table';
import ListPagination from 'components/UI/ListPagination/ListPagination';
import ReactTooltip from 'react-tooltip';

type TherapyListProps = {
  therapyResponse: TherapyResponse | undefined;
  updateFilter: Function;
  onDelete: Function;
  canManage: boolean;
  setPagination: Function;
  pagination: PaginationState;
  refetch: boolean;
};

const TherapyList: FC<TherapyListProps> = ({
  therapyResponse,
  updateFilter,
  onDelete,
  canManage,
  setPagination,
  pagination,
  refetch,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const clearSearch: boolean = useSelector(
    (state: any) => state.therapy.clearSearch,
  );
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const isAdding = location.pathname.includes('/add');
    if (
      !params.id &&
      therapyResponse?.records.length &&
      !refetch &&
      !isAdding
    ) {
      navigate(`/therapies/${therapyResponse.records[0].id}`);
    }
  }, [therapyResponse, params.id, refetch, location.pathname, navigate]);

  const handleAddClick = () => {
    if (!therapyResponse?.records) return;

    const therapyExists = therapyResponse?.records.find(
      (therapy: Therapy) =>
        therapy.name.toLowerCase().trim() === search.toLowerCase().trim(),
    );

    if (therapyExists) {
      toast.error(
        t('therapies.messages.name__exists', { name: therapyExists.name }),
      );
      return false;
    }

    navigate({
      pathname: '/therapies/add',
      search: `?name=${search}`,
    });
  };

  useEffect(() => {
    if (clearSearch) {
      setSearch('');
      updateFilter('');
      dispatch(therapyActions.resetClearSearch());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSearch]);

  return (
    <>
      <div className="master-list__search-wrapper">
        <DebouncedInput
          placeholder={t('therapies.search_box')}
          maxLength={20}
          value={search}
          type="text"
          onChange={(value) => {
            setSearch(value as string);
            updateFilter(value);
          }}
          className="form-control form-control rounded mb-2"
          data-testid="search_therapy_list_input"
        />

        {canManage ? (
          <>
            <button
              className="btn-rounded btn-rounded-sm master-list__button-add"
              onClick={handleAddClick}
              data-testid="add"
              data-tip
              data-for="therapies-add-tooltip"
            >
              <IoIosAdd size={40} />
            </button>
            <ReactTooltip id="therapies-add-tooltip" effect="solid" place="top">
              Add Therapy
            </ReactTooltip>
          </>
        ) : null}
      </div>

      {therapyResponse?.records.length === 0 && (
        <div className="alert mb-0">{t('messages.no_records')}</div>
      )}

      {therapyResponse && therapyResponse?.records.length > 0 && (
        <div className="master-list master-list__no-scroll">
          {therapyResponse.records.map((therapy: Therapy) => {
            return (
              <NavLink
                to={`/therapies/${therapy.id}`}
                className="master-list__item"
                key={therapy.id}
              >
                {therapy.name}

                {canManage && (
                  <>
                    <button
                      className="master-list__item-delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onDelete(therapy);
                      }}
                      data-testid="delete"
                      data-tip
                      data-for="therapies-delete-tooltip"
                    >
                      <IoMdTrash size={26} />
                    </button>
                    <ReactTooltip
                      id="therapies-delete-tooltip"
                      effect="solid"
                      place="top"
                    >
                      Delete Therapy
                    </ReactTooltip>
                  </>
                )}
              </NavLink>
            );
          })}
          <ListPagination
            canGetNextPage={
              Number(therapyResponse.pageIndex + 1) <
              Number(therapyResponse.totalPages)
            }
            canGetPreviousPage={Number(therapyResponse.pageIndex) > 0}
            nextPage={() => {
              setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex + 1,
              });
            }}
            previousPage={() => {
              setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex - 1,
              });
            }}
            pageCount={therapyResponse.totalPages}
            pageIndex={pagination.pageIndex}
            pageSize={pagination.pageSize}
            setPageSize={(pageSize: number) =>
              setPagination({
                ...pagination,
                pageSize,
              })
            }
            setPageIndex={(pageIndex: number) =>
              setPagination({
                ...pagination,
                pageIndex,
              })
            }
            hidePageSize
          />
        </div>
      )}
    </>
  );
};

export default TherapyList;
