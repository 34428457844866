import { CognitoUser } from 'interfaces/user';
import axiosInstance from '../config/axios';

const AuthService = {
  getMe: () => {
    return axiosInstance.get<CognitoUser>('/v1/auth/users/me');
  },
};

export default AuthService;
