import './DrugReport.scss';
import { useState, useMemo, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  getCoreRowModel,
  useReactTable,
  PaginationState,
  ColumnDef,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  ColumnFiltersState,
  Table as ReactTable,
  Column,
} from '@tanstack/react-table';
import { ReactQueryKeys } from 'constants/react-query-keys';
import Pagination from 'components/UI/Pagination/Pagination';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Table from 'components/UI/Table/Table';
import { useTranslation } from 'react-i18next';
import ReportService from 'services/ReportService';
import {
  ConcentrationTypesEnum,
  DOSE_MODE_TYPES_VALUES,
  DoseModeTypeEnum,
  DrugUnitsEnum,
  INFUSION_TYPES_VALUES,
  InfusionTypesEnum,
} from 'constants/drugs';
import DrugService from 'services/DrugService';
import { ListDrug } from 'interfaces/list-drug';
import { BsFiletypePdf } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';
import { IoMdShare, IoMdSync } from 'react-icons/io';
import { IoFilterOutline } from 'react-icons/io5';
import dayjs from 'dayjs';
import ContentHeader from 'components/UI/ContentHeader/ContentHeader';
import { REPORT_LINKS } from 'constants/report-tabs';
import { CareArea } from 'interfaces/care-area';
import TherapyService from 'services/TherapyService';
import { Therapy } from 'interfaces/therapy';
import CareAreaService from 'services/CareAreaService';
import isString from 'lodash.isstring';
import Checkbox from 'components/UI/Checkbox/Checkbox';
import intersection from 'lodash.intersection';
import union from 'lodash.union';
import { clickLinkAndDowload } from './click-link-and-download.helper';
import DebouncedInput from 'components/UI/DebouncedInput/DebouncedInput';

const infuseAsOptions: { label: string; value: number | string }[] = [
  {
    label: 'Primary',
    value: 'primary',
  },
  {
    label: 'Secondary',
    value: 'secondary',
  },
];
const allowSecondaryOptions: { label: string; value: number | string }[] = [
  {
    label: 'Off',
    value: '0',
  },
  {
    label: 'On',
    value: '1',
  },
];
const deliveryPressureOptions: { label: string; value: number | string }[] = [
  {
    label: 'device.delivery_pressure__off',
    value: '0',
  },
  {
    label: 'device.delivery_pressure__on',
    value: '1',
  },
];
const infuseUntilEmptyOptions: { label: string; value: number | string }[] = [
  {
    label: 'device.infuse_until_empty__off',
    value: '0',
  },
  {
    label: 'device.infuse_until_empty__on',
    value: '1',
  },
];
const allowRapidCompleteOptions: { label: string; value: number | string }[] = [
  {
    label: 'device.rapid_complete__off',
    value: '0',
  },
  {
    label: 'device.rapid_complete__on',
    value: '1',
  },
];
const columnsMapping = {
  concentration_limits: [
    'concentration_lower_hard_limit',
    'concentration_lower_soft_limit',
    'concentration_upper_soft_limit',
    'concentration_upper_hard_limit',
  ],
  infuse_as: 'infuse_as',
  infusion_complete: 'infusion_complete',
  loading_dose: [
    'loading_dose_amount',
    'load_amount_lower_hard_limit',
    'load_amount_lower_soft_limit',
    'load_amount_upper_soft_limit',
    'load_amount_upper_hard_limit',
    'loading_dose_time',
    'load_time_lower_hard_limit',
    'load_time_lower_soft_limit',
    'load_time_upper_soft_limit',
    'load_time_upper_hard_limit',
  ],
  bolus_dose: [
    'bolus_dose_amount',
    'bolus_amount_lower_hard_limit',
    'bolus_amount_lower_soft_limit',
    'bolus_amount_upper_soft_limit',
    'bolus_amount_upper_hard_limit',
    'bolus_dose_time',
    'bolus_time_lower_hard_limit',
    'bolus_time_lower_soft_limit',
    'bolus_time_upper_soft_limit',
    'bolus_time_upper_hard_limit',
  ],
  dose_rate: [
    'dose_rate_lower_hard_limit',
    'dose_rate_lower_soft_limit',
    'dose_rate_upper_soft_limit',
    'dose_rate_upper_hard_limit',
  ],
  dose_limits: [
    'dose_lower_hard_limit',
    'dose_lower_soft_limit',
    'dose_upper_soft_limit',
    'dose_upper_hard_limit',
  ],
  drug_amount: [
    'drug_amount_lower_hard_limit',
    'drug_amount_lower_soft_limit',
    'drug_amount_upper_soft_limit',
    'drug_amount_upper_hard_limit',
  ],
  concentration_duration: [
    'duration_lower_hard_limit',
    'duration_lower_soft_limit',
    'duration_upper_soft_limit',
    'duration_upper_hard_limit',
  ],
  allow_rapid_complete: 'allow_rapid_complete',
  infuse_until_empty: 'infuse_until_empty',
  allow_secondary: 'allow_secondary',
  delivery_pressure: 'delivery_pressure',
};

const continuousColumnFilters = [
  'concentration_limits',
  'dose_rate',
  'infuse_as',
  'allow_secondary',
  'infusion_complete',
  'delivery_pressure',
  'loading_dose',
  'bolus_dose',
];

const intermittentColumnFilters = [
  'concentration_limits',
  'concentration_duration',
  'dose_limits',
  'drug_amount',
  'infuse_as',
  'allow_secondary',
  'infusion_complete',
  'delivery_pressure',
  'allow_rapid_complete',
  'infuse_until_empty',
];

const multistepColumnFilters = [
  'concentration_limits',
  'dose_rate',
  'infuse_as',
  'allow_secondary',
  'infusion_complete',
  'delivery_pressure',
];

const infusionCompleteOptions: {
  label: string;
  value: number | string;
}[] = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Maintain Infusion Rate',
    value: 'maintain_infusion_rate',
  },
  {
    label: 'Run at KVO Rate',
    value: 'run_kvo_rate',
  },
];

const getDoseAmountValue = (value: null | number, row: any) => {
  const result = value === null || value === 0 ? '' : Number(value);
  let unit;

  if (result) {
    unit =
      row.list_concentration_type === ConcentrationTypesEnum.MILLILITER_BASED
        ? DrugUnitsEnum.MILLILITER
        : row.list_concentration_drug_unit;
    unit += ' ';

    unit +=
      DOSE_MODE_TYPES_VALUES[
        row.dose_mode_type as keyof typeof DOSE_MODE_TYPES_VALUES
      ];

    return `${result} ${unit}`;
  }

  return result;
};

const getDrugAmountValue = (value: null | number, row: any) => {
  const result = value === null || value === 0 ? '' : Number(value);

  return result ? `${result} ${row.dose_mode_unit}` : '';
};

const calculateDoseMode = (row: any) => {
  let doseMode = row.dose_mode_unit;

  if (
    row.infusion_type === InfusionTypesEnum.CONTINUOUS ||
    row.infusion_type === InfusionTypesEnum.MULTI_STEP
  ) {
    if (row.weight_based) {
      doseMode += ' / kg';
    }
  } else if (row.infusion_type === InfusionTypesEnum.INTERMITTENT) {
    if (
      row.dose_mode_type === DoseModeTypeEnum.BSA_BASED ||
      row.dose_mode_type === DoseModeTypeEnum.WEIGHT_BASED
    ) {
      doseMode +=
        ' ' +
        DOSE_MODE_TYPES_VALUES[
          row.dose_mode_type as keyof typeof DOSE_MODE_TYPES_VALUES
        ];
    }
  }

  if (row.dose_mode_time) {
    doseMode += ` / ${row.dose_mode_time}`;
  }

  return doseMode;
};

const getDoseRateValue = (value: null | number, record: any) => {
  const result = value === null || value === 0 ? '' : Number(value);

  return result
    ? `${result} ${record.dose_mode_unit} ${
        record.weight_based ? ' / kg' : ''
      } / ${record.dose_mode_time}`
    : '';
};

const INFUSION_COMPLETE = {
  run_kvo_rate: 'Run at KVO Rate',
  maintain_infusion_rate: 'Maintain Infusion Rate',
};

const TIME_UNITS = {
  minutes: 'min',
  seconds: 'sec',
};

const downloadFile = (
  data: any,
  filename: string,
  mime?: string,
  bom?: any,
) => {
  const blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
  const blob = new Blob(blobData, { type: mime || 'application/octet-stream' });
  // @ts-ignore
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    // @ts-ignore
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    clickLinkAndDowload(tempLink);

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
};

const isColumnInstance = (columnId: string) =>
  !['loading_dose', 'bolus_dose'].includes(columnId);

const DrugReport = () => {
  const { t } = useTranslation();
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });
  const [careAreaOptions, setCareAreaOptions] = useState<
    { label: string; value: number | string }[]
  >([]);
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'drug_name', desc: false },
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [filtersShown, setFiltersShown] = useState<boolean>(false);
  const [filtersButtonShown, setFiltersButtonsShown] = useState<boolean>(false);
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);

  useEffect(() => {
    CareAreaService.get({
      pageIndex: 0,
      pageSize: 40,
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
    }).then((response) => {
      const records = response.data.records.map((record: CareArea) => ({
        label: record.name,
        value: record.id!,
      }));

      setCareAreaOptions(records);
    });
  }, []);

  const updateVisibleColumnFilters = (
    infusionTypes: { label: string; value: string }[],
  ) => {
    let columnsToShow = [];
    let intersectionColumns = [
      [...continuousColumnFilters],
      [...intermittentColumnFilters],
      [...multistepColumnFilters],
    ];
    const unionColumns = union(
      [...continuousColumnFilters],
      [...intermittentColumnFilters],
      [...multistepColumnFilters],
    );

    if (infusionTypes.length) {
      setFiltersButtonsShown(true);
      intersectionColumns = [];
      infusionTypes.forEach(
        (infusionType: { label: string; value: string }) => {
          switch (infusionType.value) {
            case InfusionTypesEnum.INTERMITTENT:
              intersectionColumns.push([...intermittentColumnFilters]);
              break;
            case InfusionTypesEnum.CONTINUOUS:
              intersectionColumns.push([...continuousColumnFilters]);
              break;
            case InfusionTypesEnum.MULTI_STEP:
              intersectionColumns.push([...multistepColumnFilters]);
              break;
          }
        },
      );
    } else {
      setFiltersShown(false);
      setFiltersButtonsShown(false);
      intersectionColumns = [[...unionColumns]];
    }

    // Hide all columns
    unionColumns.forEach((columnId: string) => {
      let column;

      if (!isColumnInstance(columnId)) {
        column = {
          id: columnId,
          toggleVisibility: () => {},
        };
      } else {
        column = table.getColumn(columnId)!;
        column.toggleVisibility(false);
      }

      if (
        Array.isArray(columnsMapping[column.id as keyof typeof columnsMapping])
      ) {
        const columns: string[] = columnsMapping[
          column.id as keyof typeof columnsMapping
        ] as [];

        columns.forEach((columnId: string) => {
          const leafColumn = table.getColumn(columnId)!;
          leafColumn.toggleVisibility(false);
        });
      }

      if (isColumnInstance(columnId)) {
        column.toggleVisibility(false);
      }
    });

    // Show intersection columns
    columnsToShow = intersection(...intersectionColumns);
    setVisibleColumns(columnsToShow);

    columnsToShow.forEach((columnId: string) => {
      let column;
      if (!isColumnInstance(columnId)) {
        column = {
          id: columnId,
          toggleVisibility: () => {},
        };
      } else {
        column = table.getColumn(columnId)!;
        column.toggleVisibility(false);
      }

      if (
        Array.isArray(columnsMapping[column.id as keyof typeof columnsMapping])
      ) {
        const columns: string[] = columnsMapping[
          column.id as keyof typeof columnsMapping
        ] as [];

        columns.forEach((columnId: string) => {
          const leafColumn = table.getColumn(columnId)!;
          leafColumn.toggleVisibility(true);
        });
      }
      if (isColumnInstance(columnId)) {
        column.toggleVisibility(true);
      }
    });
  };

  useEffect(() => {
    updateVisibleColumnFilters([]);
  }, []);

  const onFilterClick = () => {
    setFiltersShown(!filtersShown);
  };

  /**
   * Filter list of drugs by name
   *
   * @param {string} inputValue The value that was entered for filtering drugs
   * @returns List of drugs
   */
  const drugOptions = (inputValue: string) =>
    DrugService.get({
      pageIndex: 0,
      pageSize: 20,
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
      columnFilters: [
        {
          id: 'name',
          value: inputValue,
        },
      ],
      includeBaseDrug: true,
    }).then((response) => {
      return response.data.records.map((record: ListDrug) => {
        return {
          label: record.name,
          value: record.id,
        };
      });
    });

  /**
   * Filter list of therapies by name
   *
   * @param {string} inputValue The value that was entered for filtering therapies
   * @returns List of therapues
   */
  const therapyOptions = (inputValue: string) =>
    TherapyService.get({
      pageIndex: 0,
      pageSize: 20,
      sorting: [
        {
          id: 'name',
          desc: false,
        },
      ],
      columnFilters: [
        {
          id: 'name',
          value: inputValue,
        },
      ],
    }).then((response) => {
      return response.data.records.map((record: Therapy) => {
        return {
          label: record.name,
          value: record.id,
        };
      });
    });

  const handleDownload = async () => {
    setIsDownloadingPDF(true);

    const unionColumns = union(
      [...continuousColumnFilters],
      [...intermittentColumnFilters],
      [...multistepColumnFilters],
    );

    const columnVisibility: { [key: string]: boolean } = {};

    // Map visible columns for the report
    unionColumns.forEach((columnId: string) => {
      let column;

      if (!isColumnInstance(columnId)) {
        column = {
          id: columnId,
          toggleVisibility: () => {},
          getIsVisible: () => true,
        };
      } else {
        column = table.getColumn(columnId)!;
        columnVisibility[columnId] = column.getIsVisible();
      }

      if (
        Array.isArray(columnsMapping[column.id as keyof typeof columnsMapping])
      ) {
        const columns: string[] = columnsMapping[
          column.id as keyof typeof columnsMapping
        ] as [];

        columns.forEach((columnId: string) => {
          const leafColumn = table.getColumn(columnId)!;
          columnVisibility[leafColumn.id] = leafColumn.getIsVisible();
        });
      }

      if (isColumnInstance(columnId)) {
        columnVisibility[column.id] = column.getIsVisible();
      }
    });

    try {
      const pdfReport = await ReportService.downloadDrugReport({
        sorting,
        columnFilters,
        columnVisibility,
      });
      downloadFile(
        pdfReport.data,
        `drug-report-${dayjs().format('YYYY-MM-DD')}.pdf`,
      );
      setIsDownloadingPDF(false);
    } catch (e) {
      console.log(e);
      setIsDownloadingPDF(false);
    }
  };

  /**
   * Show/Hide table columns
   *
   * @param column Table Column
   */
  const handleColumnCheckbox = (column: Column<any, unknown>) => {
    if (
      Array.isArray(columnsMapping[column.id as keyof typeof columnsMapping])
    ) {
      const columns: string[] = columnsMapping[
        column.id as keyof typeof columnsMapping
      ] as [];

      columns.forEach((columnId: string) => {
        const leafColumn = table.getColumn(columnId)!;
        leafColumn.toggleVisibility();
      });
    }

    if (isColumnInstance(column.id)) {
      column.toggleVisibility();
    }
  };

  /**
   * Define columns for the table
   */
  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        id: 'care_area',
        accessorFn: (row) =>
          // TODO: This is a temporary fix until we come up with proper solution for shared drugs
          row.drug_name === 'Basic mL / hr' ? 'All Care Areas' : row.care_area,
        header: t('reports.drug_report.care_area'),
        enableSorting: false,
        enableColumnFilter: true,
      },
      {
        id: 'drug_name',
        accessorFn: () => {},
        cell: ({ row }) => (
          <div className="d-flex justify-content-between">
            {row.original.drug_name}

            {row.original.drug_name === 'Basic mL / hr' ? (
              <IoMdShare size={22} color="#73e373" />
            ) : null}
          </div>
        ),
        header: t('reports.drug_report.drug_name'),
        enableSorting: true,
        enableColumnFilter: true,
        enableHiding: true,
      },
      {
        id: 'infusion_type',
        accessorFn: (row) =>
          INFUSION_TYPES_VALUES[
            row.infusion_type as keyof typeof INFUSION_TYPES_VALUES
          ],
        header: t('reports.drug_report.infusion_type'),
        enableSorting: true,
        enableColumnFilter: true,
      },
      {
        id: 'concentration',
        accessorFn: (row) => row.name,
        header: t('reports.drug_report.concentration'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'dose_mode',
        accessorFn: (row) => calculateDoseMode(row),
        header: t('reports.drug_report.dose_mode'),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'therapy',
        accessorFn: (row) => row.therapy_name,
        header: t('reports.drug_report.therapy'),
        enableSorting: false,
        enableColumnFilter: true,
      },
      {
        id: 'concentration_limits',
        header: t('reports.drug_report.concentration_limits'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'concentration_lower_hard_limit',
            accessorFn: (row) =>
              row.concentration_lower_hard_limit === null ||
              row.concentration_lower_hard_limit === 0
                ? ''
                : `${Number(row.concentration_lower_hard_limit)} ${
                    row.list_concentration_drug_unit
                  } / ml`,
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'concentration_lower_soft_limit',
            accessorFn: (row) =>
              row.concentration_lower_soft_limit === null ||
              row.concentration_lower_soft_limit === 0
                ? ''
                : `${Number(row.concentration_lower_soft_limit)} ${
                    row.list_concentration_drug_unit
                  } / ml`,
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'concentration_upper_soft_limit',
            accessorFn: (row) =>
              row.concentration_upper_soft_limit === null ||
              row.concentration_upper_soft_limit === 0
                ? ''
                : `${Number(row.concentration_upper_soft_limit)} ${
                    row.list_concentration_drug_unit
                  } / ml`,
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'concentration_upper_hard_limit',
            accessorFn: (row) =>
              row.concentration_upper_hard_limit === null ||
              row.concentration_upper_hard_limit === 0
                ? ''
                : `${Number(row.concentration_upper_hard_limit)} ${
                    row.list_concentration_drug_unit
                  } / ml`,
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'dose_rate',
        header: t('reports.drug_report.dose_rate'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'dose_rate_lower_hard_limit',
            accessorFn: (row) =>
              getDoseRateValue(row.dose_rate_lower_hard_limit, row),
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'dose_rate_lower_soft_limit',
            accessorFn: (row) =>
              getDoseRateValue(row.dose_rate_lower_soft_limit, row),
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'dose_rate_upper_soft_limit',
            accessorFn: (row) =>
              getDoseRateValue(row.dose_rate_upper_soft_limit, row),
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'dose_rate_upper_hard_limit',
            accessorFn: (row) =>
              getDoseRateValue(row.dose_rate_upper_hard_limit, row),

            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'concentration_duration',
        header: t('reports.drug_report.duration'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'duration_lower_hard_limit',
            accessorFn: (row) =>
              row.duration_lower_hard_limit === null
                ? ''
                : `${row.duration_lower_hard_limit}`,
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'duration_lower_soft_limit',
            accessorFn: (row) =>
              row.duration_lower_soft_limit === null
                ? ''
                : `${row.duration_lower_soft_limit}`,
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'duration_upper_soft_limit',
            accessorFn: (row) =>
              row.duration_upper_soft_limit === null
                ? ''
                : `${row.duration_upper_soft_limit}`,
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'duration_upper_hard_limit',
            accessorFn: (row) =>
              row.duration_upper_hard_limit === null
                ? ''
                : `${row.duration_upper_hard_limit}`,
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'dose_limits',
        header: t('reports.drug_report.dose_limits'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'dose_lower_hard_limit',
            accessorFn: (row) =>
              getDoseAmountValue(row.dose_lower_hard_limit, row),
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'dose_lower_soft_limit',
            accessorFn: (row) =>
              getDoseAmountValue(row.dose_lower_soft_limit, row),
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'dose_upper_soft_limit',
            accessorFn: (row) =>
              getDoseAmountValue(row.dose_upper_soft_limit, row),
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'dose_upper_hard_limit',
            accessorFn: (row) =>
              getDoseAmountValue(row.dose_upper_hard_limit, row),
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'drug_amount',
        header: t('reports.drug_report.drug_amount'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'drug_amount_lower_hard_limit',
            accessorFn: (row) =>
              getDrugAmountValue(row.drug_amount_lower_hard_limit, row),
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'drug_amount_lower_soft_limit',
            accessorFn: (row) =>
              getDrugAmountValue(row.drug_amount_lower_soft_limit, row),
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'drug_amount_upper_soft_limit',
            accessorFn: (row) =>
              getDrugAmountValue(row.drug_amount_upper_soft_limit, row),
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'drug_amount_upper_hard_limit',
            accessorFn: (row) =>
              getDrugAmountValue(row.drug_amount_upper_hard_limit, row),
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'infuse_as',
        accessorFn: (row) =>
          `${row.infuse_primary ? 'Primary' : ''}${
            row.infuse_primary && row.infuse_secondary ? ' | ' : ''
          }${row.infuse_secondary ? 'Secondary' : ''}`,
        header: t('reports.drug_report.infuse_as'),
        enableSorting: false,
        enableColumnFilter: true,
        className: 'text-center',
      },
      {
        id: 'allow_secondary',
        accessorFn: (row) =>
          row.infuse_primary ? (row.allow_secondary ? 'On' : 'Off') : '',
        header: t('reports.drug_report.allow_secondary'),
        enableSorting: false,
        enableColumnFilter: true,
        className: 'text-center',
      },
      {
        id: 'infusion_complete',
        accessorFn: (row) =>
          row.infusion_complete
            ? INFUSION_COMPLETE[
                row.infusion_complete as keyof typeof INFUSION_COMPLETE
              ]
            : '',
        header: t('reports.drug_report.infusion_complete'),
        enableSorting: false,
        enableColumnFilter: true,
        className: 'text-center',
      },
      {
        id: 'delivery_pressure',
        accessorFn: (row) =>
          row.delivery_pressure === null
            ? ''
            : row.delivery_pressure
              ? t('device.delivery_pressure__on')
              : t('device.delivery_pressure__off'),
        header: t('reports.drug_report.delivery_pressure'),
        enableSorting: false,
        enableColumnFilter: true,
        className: 'text-center',
      },
      {
        id: 'allow_rapid_complete',
        accessorFn: (row) =>
          row.allow_rapid_complete === null
            ? ''
            : row.allow_rapid_complete
              ? t('device.rapid_complete__on')
              : t('device.rapid_complete__off'),
        header: t('reports.drug_report.rapid_complete'),
        enableSorting: false,
        enableColumnFilter: true,
        className: 'text-center',
      },
      {
        id: 'infuse_until_empty',
        accessorFn: (row) =>
          row.infuse_until_empty === null
            ? ''
            : row.infuse_until_empty
              ? t('device.infuse_until_empty__on')
              : t('device.infuse_until_empty__off'),
        header: t('reports.drug_report.infuse_until_empty'),
        enableSorting: false,
        enableColumnFilter: true,
        className: 'text-center',
      },

      // Loading Dose
      {
        id: 'loading_dose_amount',
        header: t('reports.drug_report.loading_dose_amount'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'load_amount_lower_hard_limit',
            accessorFn: (row) =>
              !row.allow_load || row.load_amount_lower_hard_limit === null
                ? ''
                : `${Number(row.load_amount_lower_hard_limit)} ${
                    row.load_amount_units
                  } / kg`,
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'load_amount_lower_soft_limit',
            accessorFn: (row) =>
              !row.allow_load || row.load_amount_lower_soft_limit === null
                ? ''
                : `${Number(row.load_amount_lower_soft_limit)} ${
                    row.load_amount_units
                  } / kg`,
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'load_amount_upper_soft_limit',
            accessorFn: (row) =>
              !row.allow_load || row.load_amount_upper_soft_limit === null
                ? ''
                : `${Number(row.load_amount_upper_soft_limit)} ${
                    row.load_amount_units
                  } / kg`,
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'load_amount_upper_hard_limit',
            accessorFn: (row) =>
              !row.allow_load || row.load_amount_upper_hard_limit === null
                ? ''
                : `${Number(row.load_amount_upper_hard_limit)} ${
                    row.load_amount_units
                  } / kg`,
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'loading_dose_time',
        header: t('reports.drug_report.loading_dose_time'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'load_time_lower_hard_limit',
            accessorFn: (row) =>
              !row.allow_load || row.load_time_lower_hard_limit === null
                ? ''
                : `${Number(row.load_time_lower_hard_limit)} ${
                    TIME_UNITS[row.load_time_units as keyof typeof TIME_UNITS]
                  }`,
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'load_time_lower_soft_limit',
            accessorFn: (row) =>
              !row.allow_load || row.load_time_lower_soft_limit === null
                ? ''
                : `${Number(row.load_time_lower_soft_limit)} ${
                    TIME_UNITS[row.load_time_units as keyof typeof TIME_UNITS]
                  }`,
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'load_time_upper_soft_limit',
            accessorFn: (row) =>
              !row.allow_load || row.load_time_upper_soft_limit === null
                ? ''
                : `${Number(row.load_time_upper_soft_limit)} ${
                    TIME_UNITS[row.load_time_units as keyof typeof TIME_UNITS]
                  }`,
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'load_time_upper_hard_limit',
            accessorFn: (row) =>
              !row.allow_load || row.load_time_upper_hard_limit === null
                ? ''
                : `${Number(row.load_time_upper_hard_limit)} ${
                    TIME_UNITS[row.load_time_units as keyof typeof TIME_UNITS]
                  }`,
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },

      // BOLUS
      {
        id: 'bolus_dose_amount',
        header: t('reports.drug_report.bolus_dose_amount'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'bolus_amount_lower_hard_limit',
            accessorFn: (row) =>
              !row.allow_bolus || row.bolus_amount_lower_hard_limit === null
                ? ''
                : `${Number(row.bolus_amount_lower_hard_limit)} ${
                    row.bolus_amount_units
                  } / kg`,
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'bolus_amount_lower_soft_limit',
            accessorFn: (row) =>
              !row.allow_bolus || row.bolus_amount_lower_soft_limit === null
                ? ''
                : `${Number(row.bolus_amount_lower_soft_limit)} ${
                    row.bolus_amount_units
                  } / kg`,
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'bolus_amount_upper_soft_limit',
            accessorFn: (row) =>
              !row.allow_bolus || row.bolus_amount_upper_soft_limit === null
                ? ''
                : `${Number(row.bolus_amount_upper_soft_limit)} ${
                    row.bolus_amount_units
                  } / kg`,
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'bolus_amount_upper_hard_limit',
            accessorFn: (row) =>
              !row.allow_bolus || row.bolus_amount_upper_hard_limit === null
                ? ''
                : `${Number(row.bolus_amount_upper_hard_limit)} ${
                    row.bolus_amount_units
                  } / kg`,
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'bolus_dose_time',
        header: t('reports.drug_report.bolus_dose_time'),
        enableSorting: false,
        enableColumnFilter: false,
        className: 'text-center',
        columns: [
          {
            id: 'bolus_time_lower_hard_limit',
            accessorFn: (row) =>
              !row.allow_bolus || row.bolus_time_lower_hard_limit === null
                ? ''
                : `${Number(row.bolus_time_lower_hard_limit)} ${
                    TIME_UNITS[row.bolus_time_units as keyof typeof TIME_UNITS]
                  }`,
            header: t('reports.drug_report.lower_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'bolus_time_lower_soft_limit',
            accessorFn: (row) =>
              !row.allow_bolus || row.bolus_time_lower_soft_limit === null
                ? ''
                : `${Number(row.bolus_time_lower_soft_limit)} ${
                    TIME_UNITS[row.bolus_time_units as keyof typeof TIME_UNITS]
                  }`,
            header: t('reports.drug_report.lower_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'bolus_time_upper_soft_limit',
            accessorFn: (row) =>
              !row.allow_bolus || row.bolus_time_upper_soft_limit === null
                ? ''
                : `${Number(row.bolus_time_upper_soft_limit)} ${
                    TIME_UNITS[row.bolus_time_units as keyof typeof TIME_UNITS]
                  }`,
            header: t('reports.drug_report.upper_soft_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
          {
            id: 'bolus_time_upper_hard_limit',
            accessorFn: (row) =>
              !row.allow_bolus || row.bolus_time_upper_hard_limit === null
                ? ''
                : `${Number(row.bolus_time_upper_hard_limit)} ${
                    TIME_UNITS[row.bolus_time_units as keyof typeof TIME_UNITS]
                  }`,
            header: t('reports.drug_report.upper_hard_limit'),
            enableSorting: false,
            enableColumnFilter: false,
            className: 'text-center',
          },
        ],
      },
      {
        id: 'advisory_name',
        accessorFn: (row) =>
          row['advisory_name'] === null || row['advisory_name'] === ''
            ? ''
            : row['advisory_name'],
        header: t(`reports.drug_report.advisory_name`),
        enableSorting: false,
        enableColumnFilter: true,
      },
      {
        id: 'is_complete',
        accessorFn: (row) =>
          !!row.is_complete && !!row.is_complete_therapy
            ? 'Complete'
            : 'Incomplete',
        header: t('reports.drug_report.is_complete'),
        enableSorting: false,
        enableColumnFilter: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const dataQuery = useQuery(
    [
      ReactQueryKeys.REPORTS_DRUG_REPORT,
      {
        pageIndex,
        pageSize,
        sorting,
        columnFilters,
      },
    ],
    () =>
      ReportService.getDrugReport({
        pageIndex,
        pageSize,
        sorting,
        columnFilters,
      }),
    { keepPreviousData: true },
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  /**
   * Define react table
   */
  const table: ReactTable<any> = useReactTable({
    data: dataQuery.data?.data.records ?? [],
    columns,
    pageCount: dataQuery?.data?.data.totalPages ?? -1,
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableMultiSort: false,
  });

  return (
    <>
      <ContentHeader links={REPORT_LINKS} />
      <div className="main-container with-tabs">
        <div className="pb-3">
          <div>
            {filtersButtonShown ? (
              <button
                className={`btn me-3 ${
                  filtersShown ? 'btn-primary' : 'btn-outline-primary'
                }`}
                onClick={onFilterClick}
              >
                <IoFilterOutline size={24} />
                <span className="ps-2">{t('reports.column_filters')}</span>
              </button>
            ) : null}

            <button
              className={`btn btn-primary`}
              disabled={isDownloadingPDF}
              onClick={handleDownload}
            >
              {isDownloadingPDF && (
                <IoMdSync size={24} className="rotate-infinute" />
              )}
              {!isDownloadingPDF && (
                <>
                  <BsFiletypePdf
                    data-for="report-download-pdf-button"
                    data-tip={t('reports.download_pdf')}
                    size={24}
                  />
                  <ReactTooltip
                    id="report-download-pdf-button"
                    place="bottom"
                    effect="solid"
                  />
                </>
              )}
              <span className="ps-2">{t('reports.download')}</span>
            </button>
          </div>

          {filtersShown && filtersButtonShown ? (
            <div className="drug-report__filters px-2 py-3 mt-2 rounded-1">
              {visibleColumns.map((columnId) => {
                let column: any;

                switch (columnId) {
                  case 'loading_dose':
                    column = {
                      id: 'loading_dose',
                      columnDef: {
                        header: 'Loading Dose',
                        id: 'loading_dose',
                      },
                      getIsVisible: () => {
                        const c = table.getColumn('loading_dose_amount')!;
                        return c.getIsVisible();
                      },
                    };
                    break;
                  case 'bolus_dose':
                    column = {
                      id: 'bolus_dose',
                      columnDef: {
                        header: 'Bolus Dose',
                        id: 'bolus_dose',
                      },
                      getIsVisible: () => {
                        const c = table.getColumn('bolus_dose_amount')!;
                        return c.getIsVisible();
                      },
                    };
                    break;
                  default:
                    column = table.getColumn(columnId);
                }

                return (
                  <div key={column.id} className="px-1">
                    <Checkbox
                      id={column.columnDef.id + '_checkbox'}
                      // @ts-ignore
                      title={
                        isString(column.columnDef.header)
                          ? column.columnDef.header
                          : column.columnDef.meta
                      }
                      disabled={false}
                      checked={column.getIsVisible()}
                      onChange={() => handleColumnCheckbox(column)}
                      value={column.columnDef.id!}
                    />
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>

        <Table
          classes="drug-report-table"
          isFetching={dataQuery.isFetching}
          table={table}
          filters={(header) => {
            return (
              <>
                {/* Care Area filter */}
                {header.column.id === 'care_area' && (
                  <Select
                    id="care_area_filter"
                    maxMenuHeight={300}
                    onChange={header.column.setFilterValue}
                    value={header.column.getFilterValue()}
                    isMulti={true}
                    className="react-select-container-sm"
                    classNamePrefix="react-select"
                    isClearable
                    isSearchable
                    options={careAreaOptions}
                  />
                )}
                {/* Drug Name filter */}
                {header.column.id === 'drug_name' && (
                  <AsyncSelect
                    id="drug_name"
                    maxMenuHeight={300}
                    onChange={header.column.setFilterValue}
                    value={header.column.getFilterValue()}
                    isMulti={true}
                    placeholder="Search..."
                    className="react-select-container-sm"
                    classNamePrefix="react-select"
                    isClearable
                    isSearchable
                    options={[]}
                    cacheOptions={false}
                    loadOptions={drugOptions}
                  />
                )}
                {/* Infusion Type filter */}
                {header.column.id === 'infusion_type' && (
                  <Select
                    id="infusion_type"
                    aria-label="infusion_type"
                    maxMenuHeight={300}
                    onChange={(checked) => {
                      updateVisibleColumnFilters(
                        checked as { label: string; value: string }[],
                      );
                      header.column.setFilterValue(checked);
                    }}
                    value={header.column.getFilterValue()}
                    isMulti={true}
                    className="react-select-container-sm"
                    classNamePrefix="react-select"
                    isClearable
                    isSearchable={false}
                    options={Object.entries(INFUSION_TYPES_VALUES).map(
                      ([key, value]) => {
                        return {
                          // @ts-ignore
                          label: value,
                          value: key,
                        };
                      },
                    )}
                  />
                )}
                {/* Infuse As filter */}
                {header.column.id === 'infuse_as' && (
                  <Select
                    id="infuse_as_filter"
                    maxMenuHeight={300}
                    onChange={header.column.setFilterValue}
                    value={header.column.getFilterValue()}
                    isMulti={true}
                    className="react-select-container-sm"
                    classNamePrefix="react-select"
                    isClearable
                    isSearchable={false}
                    options={infuseAsOptions}
                  />
                )}
                {/* Infusion Complete  filter */}
                {header.column.id === 'infusion_complete' && (
                  <Select
                    id="infusion_complete_filter"
                    aria-label="infusion_complete_filter"
                    maxMenuHeight={300}
                    onChange={header.column.setFilterValue}
                    value={header.column.getFilterValue()}
                    isMulti={true}
                    className="react-select-container-sm"
                    classNamePrefix="react-select"
                    isClearable
                    isSearchable={false}
                    options={infusionCompleteOptions}
                  />
                )}
                {/* Infusion Until Empty  filter */}
                {header.column.id === 'infuse_until_empty' && (
                  <Select
                    id="infuse_until_empty_filter"
                    maxMenuHeight={300}
                    onChange={header.column.setFilterValue}
                    value={header.column.getFilterValue()}
                    isMulti={true}
                    className="react-select-container-sm"
                    classNamePrefix="react-select"
                    isClearable
                    isSearchable={false}
                    options={infuseUntilEmptyOptions.map((item) => ({
                      ...item,
                      label: t(item.label),
                    }))}
                  />
                )}
                {/* Allow Rapid Complete filter */}
                {header.column.id === 'allow_rapid_complete' && (
                  <Select
                    id="allow_rapid_complete_filter"
                    maxMenuHeight={300}
                    onChange={header.column.setFilterValue}
                    value={header.column.getFilterValue()}
                    isMulti={true}
                    className="react-select-container-sm"
                    classNamePrefix="react-select"
                    isClearable
                    isSearchable={false}
                    options={allowRapidCompleteOptions.map((item) => ({
                      ...item,
                      label: t(item.label),
                    }))}
                  />
                )}
                {/* Allow secondary filter */}
                {header.column.id === 'allow_secondary' && (
                  <Select
                    id="allow_secondary_filter"
                    maxMenuHeight={300}
                    onChange={header.column.setFilterValue}
                    value={header.column.getFilterValue()}
                    isMulti={true}
                    className="react-select-container-sm"
                    classNamePrefix="react-select"
                    isClearable
                    isSearchable={false}
                    options={allowSecondaryOptions}
                  />
                )}
                {/* Allow delivery pressure filter */}
                {header.column.id === 'delivery_pressure' && (
                  <Select
                    id="delivery_pressure_filter"
                    maxMenuHeight={300}
                    onChange={header.column.setFilterValue}
                    value={header.column.getFilterValue()}
                    isMulti={true}
                    className="react-select-container-sm"
                    classNamePrefix="react-select"
                    isClearable
                    isSearchable={false}
                    options={deliveryPressureOptions.map((item) => ({
                      ...item,
                      label: t(item.label),
                    }))}
                  />
                )}
                {/* Therapy filter */}
                {header.column.id === 'therapy' && (
                  <AsyncSelect
                    id="therapy_name"
                    maxMenuHeight={300}
                    onChange={header.column.setFilterValue}
                    value={header.column.getFilterValue()}
                    isMulti={true}
                    placeholder="Search..."
                    className="react-select-container-sm"
                    classNamePrefix="react-select"
                    isClearable
                    isSearchable
                    options={[]}
                    cacheOptions={false}
                    loadOptions={therapyOptions}
                  />
                )}

                {/* Is complete */}
                {header.column.id === 'is_complete' && (
                  <Select
                    id="is_complete_filter"
                    maxMenuHeight={300}
                    onChange={header.column.setFilterValue}
                    value={header.column.getFilterValue()}
                    isMulti={true}
                    className="react-select-container-sm"
                    classNamePrefix="react-select"
                    isClearable
                    isSearchable={false}
                    options={[
                      {
                        label: 'Complete',
                        value: true,
                      },
                      {
                        label: 'Incomplete',
                        value: false,
                      },
                    ]}
                  />
                )}
                {/* Advisory name  */}
                {header.column.id === 'advisory_name' && (
                  <DebouncedInput
                    value={(header.column.getFilterValue() ?? '') as string}
                    onChange={(value) => header.column.setFilterValue(value)}
                    className={
                      'form-control form-control-sm rounded color-placeholder'
                    }
                    placeholder={t(`reports.drug_report.${header.column.id}`)}
                  />
                )}
              </>
            );
          }}
        />
        <Pagination table={table} />
      </div>
    </>
  );
};

export default DrugReport;
