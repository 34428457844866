import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import Button from 'components/UI/Button/Button';
import Spinner from 'components/UI/Spinner/Spinner';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { useDispatch } from 'react-redux';
import { overlayActions } from 'store/slices/overlay';
import { useTranslation } from 'react-i18next';
import BasicModeAdvisoryReasonService from 'services/BasicModeAdvisoryReasonService';
import { BasicModeAdvisoryReason } from 'interfaces/basic-mode-advisory-reason';
import { parseErrorMessage } from 'helpers/parse-error-message';
import AdvisoryService from 'services/AdvisoryService';

type FormData = {
  reason: string;
};

const initialFormData: FormData = {
  reason: '',
};

const AdvisoryBasicModeModalForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: { ...initialFormData },
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const params = useParams();
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState(false);
  const [selectedReason, setSelectedReason] =
    useState<null | BasicModeAdvisoryReason>(null);
  const [selectReasonMode, setSelectReasonMode] = useState<boolean>(false);
  const [addedReasonsIds, setAddedReasonsIds] = useState<
    Array<string | number>
  >([]);

  const { data: addedReasons, isLoading: isAddedReasonsLoading } = useQuery(
    [ReactQueryKeys.ADVISORY_BASIC_MODE_REASONS],
    async () => {
      const assignedReasons = await AdvisoryService.getReasons(
        String(params.id),
      );

      return assignedReasons.data;
    },
    { keepPreviousData: true, enabled: !!params?.id },
  );

  const { data: reasonsData } = useQuery(
    [ReactQueryKeys.BASIC_MODE_ADVISORY_REASONS],
    () =>
      BasicModeAdvisoryReasonService.get({
        pageIndex: 0,
        pageSize: 20,
        sorting: [
          {
            id: 'reason',
            desc: false,
          },
        ],
      }),
    { keepPreviousData: true },
  );

  // Get selected reasons IDs
  useEffect(() => {
    if (addedReasons) {
      setAddedReasonsIds(addedReasons.map((record) => Number(record.id)));
    }
  }, [addedReasons]);
  /**
   * Submit form
   *
   * @param formData Reason data
   */
  const handleSubmitData = async (formData: FormData) => {
    const data = {
      reason: formData.reason.trim(),
    };
    setFormSubmitting(true);
    if (params.reasonId) {
      try {
        await BasicModeAdvisoryReasonService.update(params.reasonId, data);
        toast.success(
          t('advisories.messages.basic_mode_advisory_reason__update__success'),
        );
        queryClient.invalidateQueries([
          ReactQueryKeys.ADVISORIES,
          ReactQueryKeys.ADVISORY_BASIC_MODE_REASONS,
        ]);
        navigate(`/advisories/${params.id}?update=true`);
      } catch (e: any) {
        let message = t(
          'advisories.messages.basic_mode_advisory_reason__update__error',
        );
        if (
          e.response.data?.statusCode === 400 ||
          e.response.data?.statusCode === 502
        ) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
      }
    } else {
      // Assign reason from existing ones
      if (
        params.id &&
        selectReasonMode &&
        selectedReason &&
        selectedReason.id
      ) {
        try {
          await AdvisoryService.assignReason(+params.id, selectedReason.id);
          toast.success(
            t(
              'advisories.messages.basic_mode_advisory_reason__assign__success',
            ),
          );
          queryClient.invalidateQueries([
            ReactQueryKeys.ADVISORY_BASIC_MODE_REASONS,
          ]);
          navigate(`/advisories/${params.id}?update=true`);
        } catch (e: any) {
          let message = t(
            'advisories.messages.basic_mode_advisory_reason__assign__error',
          );
          if (
            e.response.data?.statusCode === 400 ||
            e.response.data?.statusCode === 502
          ) {
            message = parseErrorMessage(e.response.data);
          }
          toast.error(message);
        }
      }
    }
    setFormSubmitting(false);
  };

  // onKeyUp handler function
  const keyUpHandler = (event: any) => {
    if (event.code === 'Escape') {
      navigate(`/advisories/${params.id}`);
    }
  };

  useEffect(() => {
    dispatch(overlayActions.open({ path: `/advisories/${params.id}` }));
    if (params.reasonId) {
      setFormLoading(true);
      setEditing(true);
      BasicModeAdvisoryReasonService.find(params.reasonId)
        .then(({ data }: { data: BasicModeAdvisoryReason }) => {
          setValue('reason', data.reason);
          setFormLoading(false);
          setEditing(true);
        })
        .catch((_) => {
          toast.error(
            t('advisories.messages.basic_mode_advisory_loading__error'),
          );
          navigate(`/advisories/${params.id}`);
        });
    } else {
      setEditing(false);
      setSelectReasonMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReasonSelect = (reason: BasicModeAdvisoryReason) => {
    setSelectedReason(reason);
  };

  return (
    <form
      id="formid"
      autoComplete="off"
      onKeyUp={keyUpHandler}
      onSubmit={handleSubmit((data) => handleSubmitData(data))}
    >
      <div
        className={
          'modal-sidebar-body pb-4 ' +
          (formLoading || formSubmitting ? 'opacity-50' : '')
        }
      >
        {formLoading && <Spinner isAbsolute />}

        {/* Hide form if reason needs to be selected */}
        {!selectReasonMode ? (
          <div className="row">
            {/* Reason */}
            <div className="col-12">
              <label htmlFor="reason" className="form-label">
                {t('advisories.basic_mode_advisory_reason')} *
              </label>
              <input
                type="text"
                autoFocus
                className="form-control form-control-lg rounded"
                id="reason"
                data-testid="reason"
                {...register('reason', {
                  required: true,
                  maxLength: 25,
                })}
              />
              {errors?.reason?.type === 'required' && (
                <div className="invalid-feedback pt-1">
                  {t('advisories.errors.basic_mode_advisory_reason__required')}
                </div>
              )}
              {errors?.reason?.type === 'maxLength' && (
                <div className="invalid-feedback pt-1">
                  {t(
                    'advisories.errors.basic_mode_advisory_reason__max_characters',
                  )}
                </div>
              )}
            </div>
          </div>
        ) : null}

        {selectReasonMode && !isAddedReasonsLoading ? (
          <>
            <div className="h5 pb-2">
              {t('advisories.select_basic_mode_advisory_reason')}:
            </div>
            <div className="master-list">
              {reasonsData?.data?.records.map(
                (reason: BasicModeAdvisoryReason) => {
                  return (
                    <div
                      key={reason.id}
                      className={`master-list__item ${
                        selectedReason?.id === reason.id ? 'active' : ''
                      } ${
                        addedReasonsIds.includes(Number(reason.id))
                          ? 'item-disabled'
                          : ''
                      }`}
                      onClick={() => {
                        handleReasonSelect(reason);
                      }}
                    >
                      {reason.reason}
                    </div>
                  );
                },
              )}
            </div>
          </>
        ) : null}
      </div>

      <div className="modal-sidebar-footer">
        <Button
          loading={formSubmitting}
          defaultLabel={t('buttons.save')}
          loadingLabel={params.id ? t('buttons.updating') : t('buttons.saving')}
          type="submit"
          data-testid="submit_basic_mode_advisory_form"
          disabled={
            formSubmitting || formLoading || (!editing && !selectedReason)
          }
        ></Button>

        <Link
          to={`/advisories/${params.id}`}
          className={`btn btn-lg rounded btn-secondary ${
            formSubmitting || formLoading ? 'disabled' : ''
          }`}
        >
          {t('buttons.cancel')}
        </Link>
      </div>
    </form>
  );
};

export default AdvisoryBasicModeModalForm;
