import './Users.scss';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { Link, Outlet, useSearchParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { IoIosAdd, IoMdSync } from 'react-icons/io';
import { SweetAlertResult } from 'sweetalert2';
import { toast } from 'react-hot-toast';
import {
  getCoreRowModel,
  useReactTable,
  PaginationState,
  ColumnDef,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  ColumnFiltersState,
  Table as ReactTable,
} from '@tanstack/react-table';
import ContentHeader from '../../components/UI/ContentHeader/ContentHeader';
import TableActions from '../../components/UI/TableActions/TableActions';
import UserService from '../../services/UserService';
import { ReactQueryKeys } from '../../constants/react-query-keys';
import SwalAlert, {
  firePreConfirmAlert,
} from '../../components/UI/SwalAlert/SwalAlert';
import Pagination from '../../components/UI/Pagination/Pagination';
import DebouncedInput from '../../components/UI/DebouncedInput/DebouncedInput';
import { CognitoUser, User } from '../../interfaces/user';
import Select from 'react-select';
import CompanyService from '../../services/CompanyService';
import { Company } from '../../interfaces/company';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { ROLES_MAPPING, ROLE_LIST } from '../../constants/roles';
import ReactTooltip from 'react-tooltip';
import Table from '../../components/UI/Table/Table';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const STATUS = ['enabled', 'disabled'];

const Users = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const user: CognitoUser = useSelector((state: any) => state.auth.user);
  const [searchParams] = useSearchParams();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [companyId, setCompanyId] = useState<string | number | null>(null);

  // Show alert for confirming of deletion a user
  const handleTableDeleteShow = (user: User) => {
    firePreConfirmAlert({
      title: t('users.messages.modal__delete__title'),
      html: t('users.messages.modal__delete__content', {
        name: user.email,
      }),
      preConfirm: () => {
        return UserService.delete(user.id)
          .then(({ data }) => {
            return data;
          })
          .catch((error) => {
            SwalAlert.showValidationMessage(error?.response?.data?.message);
            //return false to prevent pop up from closing when running tests, check preconfirm fn
            return false;
          });
      },
    }).then((data: SweetAlertResult) => {
      if (data.isConfirmed) {
        queryClient.invalidateQueries([ReactQueryKeys.USERS]);
        toast.success(t('users.messages.delete__success'));
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMemoizedTableDeleteShow = useCallback(handleTableDeleteShow, [
    queryClient,
  ]);

  /**
   * Get companies for the company dropdown
   */
  const getCompanies = async () => {
    try {
      const {
        data: { records },
      } = await CompanyService.get({
        pageIndex: 0,
        pageSize: 1000,
      });

      setCompanies(records);
      return records;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_) {
      toast.error(t('users.messages.loading__error__companies'));
    }
  };

  /**
   * Define columns for the table
   */
  const columns = useMemo<ColumnDef<User>[]>(
    () => [
      {
        id: 'cognito_id',
        accessorFn: () => {},
        header: t('users.aws_id'),
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row }) => (
          <Link
            data-testid={`cognito_id_${row.original.cognito_id}`}
            to={`/users/${row.original.id}`}
          >{`${row.original.cognito_id}`}</Link>
        ),
      },
      {
        id: 'company_id',
        accessorFn: () => {},
        header: t('users.company'),
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row }) =>
          row.original.company_id ? (
            <Link
              data-testid={`company_id_${row.original.cognito_id}`}
              to={`/companies/${row.original.company_id}`}
            >
              {row.original.company_name}
            </Link>
          ) : (
            <div data-testid={`company_id_${row.original.cognito_id}`}>N/A</div>
          ),
      },
      {
        id: 'first_name',
        header: t('users.first_name'),
        cell: ({ row }) => (
          <div data-testid={`first_name_${row.original.cognito_id}`}>
            {row.original.first_name ? row.original.first_name : 'N/A'}
          </div>
        ),
        enableSorting: true,
        enableColumnFilter: true,
        accessorFn: () => {},
      },
      {
        id: 'last_name',
        cell: ({ row }) => (
          <div data-testid={`last_name_${row.original.cognito_id}`}>
            {row.original.last_name ? row.original.last_name : 'N/A'}
          </div>
        ),

        header: t('users.last_name'),
        enableSorting: true,
        enableColumnFilter: true,
        accessorFn: () => {},
      },
      {
        id: 'email',
        header: t('users.email'),
        cell: ({ row }) => (
          <div data-testid={`email_${row.original.cognito_id}`}>
            {`${row.original.email}`}
          </div>
        ),
        enableSorting: true,
        enableColumnFilter: true,
        accessorFn: () => {},
      },
      {
        id: 'roles',
        header: t('users.access_level'),
        cell: ({ row }) => (
          <div data-testid={`roles_${row.original.cognito_id}`}>
            {`${t(ROLES_MAPPING[row.original.roles])}`}
          </div>
        ),
        enableSorting: true,
        enableColumnFilter: true,
        accessorFn: () => {},
      },
      {
        id: 'is_enabled',
        header: t('users.status'),
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row }) => (
          <div data-testid={`is_enabled_${row.original.cognito_id}`}>
            {`${
              row.original.is_enabled
                ? t('labels.enabled')
                : t('labels.disabled')
            }`}
          </div>
        ),
        accessorFn: () => {},
      },
      {
        id: 'phone_number',
        accessorFn: () => {},
        header: t('users.phone_number'),
        enableSorting: true,
        enableColumnFilter: true,
        cell: ({ row }) => (
          <div className="table-actions-wrapper">
            <div data-testid={`phone_number_${row.original.cognito_id}`}>
              {row.original.phone_number
                ? formatPhoneNumberIntl(row.original.phone_number)
                : 'N/A'}
            </div>
            <TableActions
              editLink={`/users/${row.original.id}`}
              onDelete={
                row.original.cognito_id !== user.Username
                  ? () => {
                      handleMemoizedTableDeleteShow(row.original);
                    }
                  : null
              }
            />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleMemoizedTableDeleteShow],
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [syncing, setSyncing] = useState<boolean>(false);

  const dataQuery = useQuery(
    [
      ReactQueryKeys.USERS,
      {
        pageIndex,
        pageSize,
        sorting,
        columnFilters,
      },
    ],
    () =>
      UserService.get({
        pageIndex,
        pageSize,
        sorting,
        columnFilters,
      }),
    { keepPreviousData: true },
  );

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  /**
   * Temporary sync of users that are in cognito
   */
  const handleSync = async () => {
    setSyncing(true);

    try {
      await UserService.sync();
      queryClient.invalidateQueries([ReactQueryKeys.USERS]);
      toast.success('The users were successfully synced.');
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_) {
      toast.error('The users could not be synced.');
    } finally {
      setSyncing(false);
    }
  };

  /**
   * Define react table
   */
  const table: ReactTable<User> = useReactTable({
    data: dataQuery.data?.data.records ?? [],
    columns,
    pageCount: dataQuery?.data?.data.totalPages ?? -1,
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableMultiSort: false,
  });

  useEffect(() => {
    if (searchParams.get('company_id')) {
      setCompanyId(searchParams.get('company_id'));
    }
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (companies.length && !!companyId) {
      const company = companies.find((c) => c.id === companyId);

      if (company) {
        setColumnFilters([
          {
            id: 'company_id',
            value: {
              label: company.name,
              value: String(company.id),
            },
          },
        ]);
      }
    }
  }, [companies, companyId]);

  return (
    <>
      <ContentHeader
        title={t('users.title')}
        actions={
          <div className="d-flex">
            <button
              data-tip="Sync"
              data-for="content-header-button"
              data-testid="sync-button"
              disabled={syncing}
              className={`btn-rounded me-3 ${syncing ? 'rotate-infinute' : ''}`}
              onClick={handleSync}
            >
              <IoMdSync size={40} />
            </button>
            <span data-for="content-header-button" data-tip={t('buttons.add')}>
              <Link
                to="/users/add"
                className="btn-rounded"
                data-testid="add-btn"
              >
                <IoIosAdd size={40} />
              </Link>
            </span>
            <ReactTooltip
              id="content-header-button"
              place="bottom"
              effect="solid"
            />
          </div>
        }
      />

      <Table
        classes="users-table"
        isFetching={dataQuery.isFetching}
        table={table}
        filters={(header) => {
          return (
            <>
              {/* Company filter */}
              {header.column.id === 'company_id' && (
                <Select
                  id="accessLevel"
                  aria-label="company_id"
                  maxMenuHeight={300}
                  onChange={header.column.setFilterValue}
                  value={header.column.getFilterValue()}
                  isMulti={false}
                  className="react-select-container-sm"
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable
                  options={companies.map((company) => ({
                    label: company.name,
                    value: company.id,
                  }))}
                />
              )}

              {/* Roles filter  */}
              {header.column.id === 'roles' && (
                <Select
                  id="roleSelect"
                  aria-label="roles"
                  maxMenuHeight={300}
                  onChange={header.column.setFilterValue}
                  value={header.column.getFilterValue()}
                  isMulti={false}
                  className="react-select-container-sm"
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable
                  options={ROLE_LIST.map((role) => ({
                    label: t(ROLES_MAPPING[role]),
                    value: role,
                  }))}
                />
              )}

              {/* Status filter */}
              {header.column.id === 'is_enabled' && (
                <Select
                  aria-label="is_enabled"
                  id="accessLevel"
                  maxMenuHeight={300}
                  onChange={header.column.setFilterValue}
                  value={header.column.getFilterValue()}
                  isMulti={false}
                  className="react-select-container-sm"
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable
                  options={STATUS.map((status) => {
                    return {
                      // @ts-ignore
                      label: t(`labels.${status}`),
                      value: status,
                    };
                  })}
                />
              )}

              {!['company_id', 'roles', 'is_enabled'].includes(
                header.column.id,
              ) && (
                <DebouncedInput
                  value={(header.column.getFilterValue() ?? '') as string}
                  type="text"
                  onChange={(value) => header.column.setFilterValue(value)}
                  className="form-control form-control-sm rounded"
                  data-testid={`filter_input_${header.column.id}`}
                />
              )}
            </>
          );
        }}
      />
      <Pagination table={table} />
      <Outlet />
    </>
  );
};

export default Users;
