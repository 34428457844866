import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import axiosInstance from 'config/axios';
import { User } from 'interfaces/user';
import { PaginationResponse } from 'interfaces/pagination-response';

export type UserResponse = PaginationResponse<User>;

const UserService = {
  getMe: () => {
    return axiosInstance.get('/v1/users/me');
  },

  updateMe: (data: Partial<User>) => {
    return axiosInstance.patch<User>('/v1/users/me', data);
  },

  sync: () => {
    return axiosInstance.post<any>('/v1/users/sync');
  },

  create: (data: Partial<User>) => {
    return axiosInstance.post<User>('/v1/users', data);
  },

  update: (id: string | number, data: Partial<User>) => {
    return axiosInstance.patch<User>(`/v1/users/${id}`, data);
  },

  find: (id: string | number) => {
    return axiosInstance.get<User>(`/v1/users/${id}`);
  },

  delete: (id: string | number) => {
    return axiosInstance.delete<User>(`/v1/users/${id}`);
  },

  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters: ColumnFilter[];
  }) => {
    return axiosInstance.get<UserResponse>('/v1/users', {
      params: queryParams,
    });
  },
};

export default UserService;
