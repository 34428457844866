import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IoIosAdd,
  IoIosArrowForward,
  IoIosWarning,
  IoMdShare,
  IoMdTrash,
} from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { sortConcentrations, sortTherapies } from 'helpers/drugs';
import { CareArea } from 'interfaces/care-area';
import { Facility } from 'interfaces/facility';
import DrugService from 'services/DrugService';
import {
  deviceActions,
  selectExpandedDrugs,
  selectExpandedTherapies,
} from 'store/slices/device';
import {
  DrugConcentration,
  GetDrugsWithConcentrationsRecord,
} from 'interfaces/drug-concentration';
import SwalAlert, {
  firePreConfirmAlert,
} from 'components/UI/SwalAlert/SwalAlert';
import { SweetAlertResult } from 'sweetalert2';
import { toast } from 'react-hot-toast';
import { Drug } from 'interfaces/drug';
import useBlockerExceptionalCases from 'hooks/useBlockerExceptionalCases';
import ReactTooltip from 'react-tooltip';
import { parseErrorMessage } from 'helpers/parse-error-message';
import { ConcentrationTypesEnum } from 'constants/drugs';

type ConcentrationsChildren = {
  group: boolean;
  hasAssignedTherapy?: boolean;
  drug_name: GetDrugsWithConcentrationsRecord['name'];
  calculated: GetDrugsWithConcentrationsRecord['concentrations'][0]['name'];
} & GetDrugsWithConcentrationsRecord['concentrations'][0];

interface CommonFeaturesGroupedAndUniqueConc
  extends Pick<GetDrugsWithConcentrationsRecord, 'is_basic'> {
  index: number;
  drug_id: GetDrugsWithConcentrationsRecord['id'];
  list_concentration_id: GetDrugsWithConcentrationsRecord['concentrations'][0]['list_concentration_id'];
}

// Grouped has 3 keys: 'type' + 'list_drug_id' + 'calculated'. Unique DOES NOT
interface GroupedConcentrations extends CommonFeaturesGroupedAndUniqueConc {
  group: true;
  children: ConcentrationsChildren[];
  list_drug_id: GetDrugsWithConcentrationsRecord['list_drug_id'];
  calculated: GetDrugsWithConcentrationsRecord['concentrations'][0]['name'];
  type: GetDrugsWithConcentrationsRecord['concentrations'][0]['type'];
}
interface UniqueConcentrations extends CommonFeaturesGroupedAndUniqueConc {
  group: false;
  children: ConcentrationsChildren[];
}

type GroupedAndUniqueConcentrations =
  | UniqueConcentrations
  | GroupedConcentrations;

interface ProcessedDrugsWithConcentrations
  extends Pick<
    GetDrugsWithConcentrationsRecord,
    'id' | 'is_active' | 'is_basic' | 'list_drug_id' | 'name' | 'shared'
  > {
  is_complete: boolean;
  is_complete_therapy: boolean;
  concentrations: GroupedAndUniqueConcentrations[];
}

type DrugsProps = {
  disabledAdding: boolean;
  facility?: Facility;
  careArea?: CareArea;
  drugConcentration?: DrugConcentration;
  drug?: Drug;
};

/**
 * Count duplicate concentrations per drug
 *
 * @param {GetDrugsWithConcentrationsRecord['concentrations']} concentrations
 * @returns
 */
const getConcentrationDuplicateCount = (
  concentrations: GetDrugsWithConcentrationsRecord['concentrations'],
) => {
  const duplicates: Record<string, number> = {};

  // Group by list concentration id for duplicates
  concentrations.forEach((concentration) => {
    duplicates[String(concentration?.list_concentration_id)] =
      (duplicates[String(concentration.list_concentration_id)] || 0) + 1;
  });

  return duplicates;
};

const DELETE_TEST_ID_ROOT = 'delete-concentration-';
const UPDATE_CONCENTRATION_TEST_ID_ROOT = 'update-selected-concentration-test-';

const Drugs: FC<DrugsProps> = ({
  disabledAdding,
  facility,
  careArea,
  drugConcentration,
  drug,
}) => {
  const { t } = useTranslation();
  const handleBlock = useBlockerExceptionalCases();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const expandedDrugs: number[] = useSelector(selectExpandedDrugs);
  const expandedTherapies: number[] = useSelector(selectExpandedTherapies);
  const isDrugExpanded = (id: number | string) => expandedDrugs.includes(+id);
  const isTherapyExpanded = (id: number | string) =>
    expandedTherapies.includes(+id);

  const { data: drugs } = useQuery(
    [ReactQueryKeys.DRUGS, facility, careArea],
    async () => {
      const drugs = await DrugService.getDrugsWithConcentrations({
        facilityId: Number(facility?.id),
        careAreaId: Number(careArea?.id),
      });

      const result = drugs.data.records.map(
        (drug): ProcessedDrugsWithConcentrations => {
          const concentrations = sortConcentrations(
            drug.concentrations,
          ) as GetDrugsWithConcentrationsRecord['concentrations'];

          const groupedConcentrations: GroupedAndUniqueConcentrations[] = [];

          const concentrationDuplicateCount =
            getConcentrationDuplicateCount(concentrations);

          concentrations.forEach((concentration, index) => {
            if (
              concentrationDuplicateCount[
                String(
                  concentration.list_concentration_id,
                ) as keyof typeof concentrationDuplicateCount
              ]
            ) {
              // Group in therapies
              if (
                concentrationDuplicateCount[
                  String(
                    concentration.list_concentration_id,
                  ) as keyof typeof concentrationDuplicateCount
                ] > 1
              ) {
                const groupConcentrations = concentrations.filter(
                  (c) =>
                    String(concentration.list_concentration_id) ===
                    String(c.list_concentration_id),
                );

                groupedConcentrations.push({
                  group: true,
                  index,
                  drug_id: drug.id,
                  list_drug_id: groupConcentrations[0].list_drug_id,
                  type: groupConcentrations[0].type,
                  list_concentration_id:
                    groupConcentrations[0].list_concentration_id,
                  calculated: groupConcentrations[0].name,
                  //@ts-ignore
                  children: sortTherapies(
                    groupConcentrations.map((concentration, index) => {
                      return {
                        ...concentration,
                        drug_name: drug.name,
                        group: true,
                        index,
                        calculated: concentration.therapy_name
                          ? concentration.therapy_name
                          : `${t('device.therapy')} #${index + 1}`,
                        hasAssignedTherapy: !!concentration.therapy_name,
                        is_complete:
                          concentration.is_complete &&
                          concentration.is_complete_therapy,
                      };
                    }),
                    t('device.therapy'),
                  ),
                });
              }
              // Unique concentration
              else {
                groupedConcentrations.push({
                  group: false,
                  index,
                  drug_id: drug.id,
                  is_basic: concentration.is_basic,
                  list_concentration_id: concentration.list_concentration_id,
                  children: [
                    {
                      ...concentration,
                      is_complete:
                        concentration.is_complete &&
                        concentration.is_complete_therapy,
                      drug_name: drug.name,
                      group: false,
                      is_basic: concentration.is_basic,
                      calculated: concentration.name,
                    },
                  ],
                });
              }

              // Remove concentration from duplicate count
              delete concentrationDuplicateCount[
                String(
                  concentration.list_concentration_id,
                ) as keyof typeof concentrationDuplicateCount
              ];
            }
          });

          return {
            ...drug,
            is_complete: drug.concentrations.every(
              (c) => c.is_complete && c.is_complete_therapy,
            ),
            concentrations: groupedConcentrations,
          };
        },
      );

      return result;
    },
    { keepPreviousData: false, enabled: !!facility && !!careArea },
  );

  const handleAddClick = async () => {
    const canWeContinue = await handleBlock(() => {
      return true;
    });

    if (!canWeContinue) return;

    dispatch(deviceActions.setAddingConcentration());
    dispatch(deviceActions.clearSelectedDrugConcentration());
    dispatch(deviceActions.clearSelectedDrug());
  };

  /**
   * Update selected concentration
   *
   * @param {DrugConcentration} concentration
   */
  const updateSelectedConcentration = async (
    concentration: ConcentrationsChildren,
  ) => {
    const canWeContinue = await handleBlock(() => {
      return true;
    });

    if (!canWeContinue) return;
    dispatch(
      deviceActions.updateSelectedDrugConcentration({
        concentration,
      }),
    );
    dispatch(deviceActions.clearAddingCareArea());
    dispatch(deviceActions.clearAddingConcentration());
    dispatch(
      deviceActions.setExpandedDrug({
        id: +concentration.drug_id,
      }),
    );
    dispatch(
      deviceActions.setExpandedTherapies({
        id: +concentration.list_concentration_id,
      }),
    );
  };

  /**
   * Update Selected Drug
   *
   * @param {Drug} drug
   */
  const updateSelectedDrug = async (drug: ProcessedDrugsWithConcentrations) => {
    const canWeContinue = await handleBlock(() => {
      return true;
    });

    if (!canWeContinue) return;
    dispatch(
      deviceActions.updateSelectedDrug({
        drug: {
          id: drug.id,
          list_drug_id: drug.list_drug_id,
          name: drug.name,
          is_basic: drug.is_basic,
        },
      }),
    );
    dispatch(deviceActions.clearAddingCareArea());
    dispatch(deviceActions.clearAddingConcentration());
    dispatch(
      deviceActions.setExpandedDrug({
        id: +drug.id,
      }),
    );
    dispatch(deviceActions.clearExpandedTherapies());
  };

  /**
   * Update Selected Therapy
   *
   * @param updateSelectedTherapy
   */
  const updateSelectedTherapy = async (
    updateSelectedTherapy: GroupedAndUniqueConcentrations,
  ) => {
    const canWeContinue = await handleBlock(() => {
      return true;
    });

    if (!canWeContinue) return;
    dispatch(
      deviceActions.setExpandedDrug({
        id: +updateSelectedTherapy.drug_id,
      }),
    );
    dispatch(
      deviceActions.setExpandedTherapies({
        id: +updateSelectedTherapy.list_concentration_id,
      }),
    );
  };

  const handleDrugArrowClick = (drugId: number) => {
    dispatch(
      deviceActions.updateExpandedDrugs({
        id: drugId,
      }),
    );
  };

  const handleTherapyArrowClick = (listDrugId: number) => {
    dispatch(
      deviceActions.updateExpandedTherapies({
        id: listDrugId,
      }),
    );
  };

  const handleConcentrationDelete = async (
    concentration: ConcentrationsChildren,
  ) => {
    const canWeContinue = await handleBlock(() => {
      return true;
    });

    if (!canWeContinue) return;

    firePreConfirmAlert({
      title: t('device.messages.modal__unassign_concentration__title'),
      html: t('device.messages.modal__unassign_concentration__content', {
        name: concentration.calculated,
        drugName: concentration.drug_name,
      }),
      preConfirm: () => {
        return DrugService.deleteConcentration(String(concentration.id), {
          careAreaId: Number(careArea?.id),
          facilityId: Number(facility?.id),
        })
          .then(({ data }) => {
            return data;
          })
          .catch((error) => {
            SwalAlert.showValidationMessage(error?.response?.data?.message);
          });
      },
    }).then((data: SweetAlertResult) => {
      if (data.isConfirmed) {
        queryClient.invalidateQueries([ReactQueryKeys.DRUGS]);
        queryClient.invalidateQueries([ReactQueryKeys.FACILITY_CARE_AREAS]);
        dispatch(deviceActions.clearSelectedDrugConcentration());
        toast.success(t('device.messages.concentration__unassugn__success'));
      }
    });
  };

  const handleDrugDelete = async (drug: ProcessedDrugsWithConcentrations) => {
    const canWeContinue = await handleBlock(() => {
      return true;
    });

    if (!canWeContinue) return;

    firePreConfirmAlert({
      title: t('device.messages.modal__unassign_drug__title'),
      html: t('device.messages.modal__unassign_drug__content', {
        name: drug.name,
        careArea: careArea?.name,
      }),
      preConfirm: () => {
        return DrugService.deleteDrug(String(drug.id), {
          careAreaId: Number(careArea?.id),
          facilityId: Number(facility?.id),
        })
          .then(({ data }) => {
            return data;
          })
          .catch((error) => {
            SwalAlert.showValidationMessage(error?.response?.data?.message);
          });
      },
    }).then((data: SweetAlertResult) => {
      if (data.isConfirmed) {
        dispatch(deviceActions.clearSelectedDrug());
        queryClient.invalidateQueries([ReactQueryKeys.FACILITY_CARE_AREAS]);
        queryClient.invalidateQueries([ReactQueryKeys.DRUGS]);
        toast.success(t('device.messages.drug__unassugn__success'));
      }
    });
  };

  const handleAssignTherapy = async (
    listConcentrationAndListDrug:
      | GroupedConcentrations
      | ConcentrationsChildren,
  ) => {
    const canWeContinue = await handleBlock(() => {
      return true;
    });

    if (!canWeContinue) return;

    try {
      const { list_concentration_id, list_drug_id, calculated } =
        listConcentrationAndListDrug;

      const { data: concentration } =
        await DrugService.assignConcentrationToCareArea({
          careAreaId: Number(careArea?.id),
          facilityId: Number(facility?.id),
          listConcentrationId: Number(list_concentration_id),
          listDrugId: Number(list_drug_id),
        });

      dispatch(
        deviceActions.setExpandedDrug({
          id: +concentration.drug_id,
        }),
      );
      dispatch(
        deviceActions.setExpandedTherapies({
          id: +concentration.list_concentration_id,
        }),
      );
      dispatch(deviceActions.clearSelectedDrugConcentration());
      dispatch(
        deviceActions.updateSelectedDrugConcentration({
          concentration,
        }),
      );

      queryClient.invalidateQueries([ReactQueryKeys.DRUGS]);

      toast.success(
        t('device.messages.concentration_assign__success', {
          name: calculated,
          interpolation: {
            escapeValue: false,
          },
        }),
      );
    } catch (e: any) {
      console.log(e);
      let message = t('device.messages.concentration_assign__error');
      if (
        e.response.data?.statusCode === 400 ||
        e.response.data?.statusCode === 502
      ) {
        message = parseErrorMessage(e.response.data);
      }
      toast.error(message);
    }
  };

  const AddTherapyBtn = ({
    concentration,
    toolTipId,
  }: {
    concentration: GroupedConcentrations | ConcentrationsChildren;
    toolTipId: string;
  }) => (
    <button
      className="btn-rounded btn-rounded-xsm"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleAssignTherapy(concentration);
      }}
      disabled={disabledAdding}
      data-testid="add-concentration-button"
      data-tip={t('device.assign_therapy')}
      data-for={toolTipId}
      id="add-therapy-btn"
    >
      <IoIosAdd />

      <ReactTooltip id={toolTipId} effect="solid" place="top">
        {t('device.assign_therapy')}
      </ReactTooltip>
    </button>
  );

  const splitFullConcentrationText = (text: string) => {
    const splitStr = text.split('(');

    const firstPart = splitStr[0];
    const secondPart = '(' + splitStr[1];

    return (
      <div>
        <div>{firstPart}</div>
        <div>{secondPart}</div>
      </div>
    );
  };

  return (
    <>
      <h4 className="device__drugs--title pb-1" data-testid="drugs-component">
        {t('device.drugs')}

        <button
          className="btn-rounded btn-rounded-sm master-list__button-add"
          onClick={handleAddClick}
          disabled={disabledAdding}
          data-testid="add-drugs-button"
          data-tip
          data-for="assign-concentraton-tooltip"
        >
          <IoIosAdd size={40} />
        </button>

        <ReactTooltip
          id="assign-concentraton-tooltip"
          effect="solid"
          place="top"
        >
          {t('device.assign_drug')}
        </ReactTooltip>
      </h4>
      {drugs && drugs.length === 0 && (
        <div className="alert mb-0">{t('messages.no_records')}</div>
      )}
      {drugs && drugs.length > 0 && (
        <div className="master-list device__drugs--full-list">
          {drugs.map((item) => {
            return (
              <div key={`drug_wrapper_${item.id}`}>
                <div
                  className={`device-list-drug--concentration-item ${
                    drug && drug.id === item.id ? 'selected' : ''
                  }`}
                  key={`drug_${item.id}`}
                >
                  <IoIosArrowForward
                    size={16}
                    className={`device-list-drug--concentration-arrow ${
                      isDrugExpanded(item.id)
                        ? 'device-list_rotate-icon-90'
                        : ''
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleDrugArrowClick(+item.id);
                    }}
                    data-testid={'drug-arrow-' + item.id}
                  />
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      updateSelectedDrug(item);
                    }}
                    className="split-text-and-icons"
                    data-testid={'update-selected-drug-test-' + item.id}
                  >
                    {item.name}
                    <div className="device-list-drug--icons">
                      {item.shared ? (
                        <>
                          <IoMdShare
                            size={22}
                            color="#73e373"
                            data-tip
                            data-for="drug-shared-tooltip"
                          />
                          <ReactTooltip
                            id="drug-shared-tooltip"
                            effect="solid"
                            place="top"
                          >
                            Shared Drug
                          </ReactTooltip>
                        </>
                      ) : null}

                      {!item.is_complete ? (
                        <>
                          <IoIosWarning
                            className="device-list-drug--concentration-warning"
                            size={22}
                            data-tip
                            data-for="drug-incomplete-tooltip"
                          />

                          <ReactTooltip
                            id="drug-incomplete-tooltip"
                            effect="solid"
                            place="top"
                          >
                            Incomplete Drug
                          </ReactTooltip>
                        </>
                      ) : null}

                      {!item.is_basic ? (
                        <>
                          <button
                            className="device-list-drug--concentration-delete"
                            onClick={() => handleDrugDelete(item)}
                            data-testid={'delete-drug-' + item.id}
                            data-tip
                            data-for="drug-delete-tooltip"
                          >
                            <IoMdTrash size={22} />
                          </button>

                          <ReactTooltip
                            id="drug-delete-tooltip"
                            effect="solid"
                            place="top"
                          >
                            Remove Drug
                          </ReactTooltip>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* Concentrations for the drug */}
                {Array.isArray(item.concentrations) &&
                item.concentrations.length === 0 ? (
                  <div
                    key={`no_concentrations_${item.id}`}
                    className={`alert mb-0 device-list-drug--concentration-item ${
                      isDrugExpanded(+item.id) ? 'd-block' : 'd-none'
                    }`}
                  >
                    {t('messages.no_records')}
                  </div>
                ) : null}

                {Array.isArray(item.concentrations) &&
                  item.concentrations.length > 0 &&
                  item.concentrations.map((concentration) => {
                    return concentration.group ? (
                      <div
                        key={`concentration_group_${item.id}_${concentration.calculated}`}
                        className={` ${
                          isDrugExpanded(+concentration.drug_id)
                            ? 'd-block'
                            : 'd-none'
                        }`}
                      >
                        {/* Concentration Group */}
                        <div
                          onClick={() => {
                            dispatch(
                              deviceActions.clearSelectedDrugConcentration(),
                            );
                          }}
                          className="device-list-drug--concentration-item device-list-drug--concentration-level1 device-list-drug--concentration-group"
                        >
                          <IoIosArrowForward
                            size={16}
                            className={`device-list-drug--concentration-arrow ${
                              isDrugExpanded(+concentration.drug_id) &&
                              isTherapyExpanded(
                                +concentration.list_concentration_id,
                              )
                                ? 'device-list_rotate-icon-90'
                                : ''
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              handleTherapyArrowClick(
                                +concentration.list_concentration_id,
                              );
                            }}
                            data-testid={
                              'therapy-arrow-' +
                              concentration.list_concentration_id
                            }
                          />
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              updateSelectedTherapy(concentration);
                            }}
                            data-testid={`update-selected-therapy-test-${concentration.list_concentration_id}`}
                            className="split-text-and-icons"
                          >
                            {concentration.type === ConcentrationTypesEnum.FULL
                              ? splitFullConcentrationText(
                                  concentration?.calculated as string,
                                )
                              : concentration.calculated}
                            {isDrugExpanded(+concentration.drug_id) &&
                              isTherapyExpanded(
                                +concentration.list_concentration_id,
                              ) && (
                                <div className="device-list-drug--icons">
                                  <AddTherapyBtn
                                    concentration={concentration}
                                    toolTipId="assign-grouped-therapy-tooltip"
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                        {/* Concentrations - Therapies */}
                        {concentration.children.map((item) => (
                          <div
                            key={`concentration_${item.id}`}
                            className={`device-list-drug--concentration-item device-list-drug--concentration-level2 ${
                              drugConcentration &&
                              drugConcentration.id === item.id
                                ? 'selected'
                                : ''
                            } ${
                              isDrugExpanded(+item.drug_id) &&
                              isTherapyExpanded(+item.list_concentration_id)
                                ? 'd-block'
                                : 'd-none'
                            }`}
                          >
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                updateSelectedConcentration(item);
                              }}
                              data-testid={
                                UPDATE_CONCENTRATION_TEST_ID_ROOT + item.id
                              }
                              className="split-text-and-icons"
                            >
                              {item.calculated}

                              <div className="device-list-drug--icons">
                                {!item.is_complete ? (
                                  <>
                                    <IoIosWarning
                                      className="device-list-drug--concentration-warning"
                                      size={22}
                                      data-tip
                                      data-for="therapy-incomplete-tooltip"
                                    />

                                    <ReactTooltip
                                      id="therapy-incomplete-tooltip"
                                      effect="solid"
                                      place="top"
                                    >
                                      Incomplete Therapy
                                    </ReactTooltip>
                                  </>
                                ) : null}

                                <>
                                  <button
                                    className="device-list-drug--concentration-delete"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleConcentrationDelete(item);
                                    }}
                                    data-testid={DELETE_TEST_ID_ROOT + item.id}
                                    data-tip
                                    data-for="therapy-delete-tooltip"
                                  >
                                    <IoMdTrash size={22} />
                                  </button>
                                  <ReactTooltip
                                    id="therapy-delete-tooltip"
                                    effect="solid"
                                    place="top"
                                  >
                                    Remove Therapy
                                  </ReactTooltip>
                                </>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      // Concentrations
                      concentration.children.map((item) => (
                        <div
                          key={`concentration_${item.id}`}
                          className={`device-list-drug--concentration-item device-list-drug--concentration-level1 ${
                            drugConcentration &&
                            drugConcentration.id === item.id
                              ? 'selected'
                              : ''
                          } ${
                            isDrugExpanded(+item.drug_id) ? 'd-block' : 'd-none'
                          } `}
                        >
                          <div
                            onClick={(e) => {
                              e.preventDefault();
                              updateSelectedConcentration(item);
                            }}
                            data-testid={
                              UPDATE_CONCENTRATION_TEST_ID_ROOT + item.id
                            }
                            className="split-text-and-icons"
                          >
                            {item.type === ConcentrationTypesEnum.FULL
                              ? splitFullConcentrationText(item.calculated)
                              : item.calculated}
                            <div className="device-list-drug--icons">
                              {!item.is_complete ? (
                                <>
                                  <IoIosWarning
                                    className="device-list-drug--concentration-warning"
                                    size={22}
                                    data-tip
                                    data-for="concentration-incomplete-tooltip"
                                  />
                                  <ReactTooltip
                                    id="concentration-incomplete-tooltip"
                                    effect="solid"
                                    place="top"
                                  >
                                    Incomplete Concentration
                                  </ReactTooltip>
                                </>
                              ) : null}

                              {!item.is_basic &&
                              drugConcentration?.id === item.id ? (
                                <AddTherapyBtn
                                  concentration={item}
                                  toolTipId="assign-therapy-tooltip"
                                />
                              ) : null}

                              {!item.is_basic ? (
                                <>
                                  <button
                                    className="device-list-drug--concentration-delete"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleConcentrationDelete(item);
                                    }}
                                    data-testid={DELETE_TEST_ID_ROOT + item.id}
                                    data-tip
                                    data-for="concentration-delete-tooltip"
                                  >
                                    <IoMdTrash size={22} />
                                  </button>
                                  <ReactTooltip
                                    id="concentration-delete-tooltip"
                                    effect="solid"
                                    place="top"
                                  >
                                    Remove Concentration
                                  </ReactTooltip>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ))
                    );
                  })}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Drugs;
