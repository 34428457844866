import './TherapyForm.scss';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from '../../../components/UI/Button/Button';
import TherapyService from '../../../services/TherapyService';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from '../../../constants/react-query-keys';
import Spinner from '../../../components/UI/Spinner/Spinner';
import intersection from 'lodash.intersection';
import { ADMINISTRATION, AUTHOR } from '../../../constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import { CognitoUser } from '../../../interfaces/user';
import { usePrompt } from '../../../hooks/useBlocker';
import { parseErrorMessage } from '../../../helpers/parse-error-message';
import { useTranslation } from 'react-i18next';
import { therapyActions } from '../../../store/slices/therapy';
import { IoIosCreate } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';

export type FormData = {
  id?: string | number;
  name: string;
};

const initialFormData: FormData = {
  name: '',
};

const TherapyForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const queryClient = useQueryClient();
  const user: CognitoUser = useSelector((state: any) => state.auth.user);
  const canManage =
    user && !!intersection(user.roles, [ADMINISTRATION, AUTHOR]).length;
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [record, setRecord] = useState({});
  const [forceNavigate, setForceNavigate] = useState(false);
  const [searchParams] = useSearchParams();
  const [editing, setEditing] = useState(false);
  const [editingName, setEditingName] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: { ...initialFormData },
  });

  usePrompt(
    t('messages.unsaved_changes'),
    isDirty && !forceNavigate && !formSubmitting,
  );

  useEffect(() => {
    const controller = new AbortController();

    if (params.id) {
      setEditing(true);
      setFormLoading(true);
      setEditingName(false);
      TherapyService.find(params.id, { signal: controller.signal })
        .then(({ data }) => {
          setRecord(data);
          reset(
            Object.assign(
              { ...initialFormData },
              {
                name: data.name,
              },
            ),
          );
          setForceNavigate(false);
        })
        .catch((error: any) => {
          //if api call aborted do nothing
          if (error?.name === 'CanceledError') {
            return;
          }

          setForceNavigate(true);
          toast.error(t('therapies.messages.loading__error'));
          setTimeout(() => {
            navigate('/therapies');
          }, 100);
        })
        .finally(() => {
          setFormLoading(false);
        });
    } else {
      setEditing(false);
      const name = searchParams.get('name') || '';
      setRecord({});
      reset({ ...initialFormData });
      setValue('name', name, { shouldDirty: true });
      setForceNavigate(false);
    }

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  /**
   * Submit form and create therapy
   *
   * @param formData Therapy data
   */
  const handleSubmitData = async (formData: FormData) => {
    setFormSubmitting(true);

    if (params.id) {
      try {
        const { data: therapy } = await TherapyService.update(params.id, {
          ...formData,
          name: formData.name.trim(),
        });
        setFormSubmitting(false);
        setRecord(therapy);
        reset(therapy);
        setEditingName(false);
        queryClient.invalidateQueries([ReactQueryKeys.THERAPIES]);
        toast.success(t('therapies.messages.update__success'));
      } catch (e: any) {
        let message = t('therapies.messages.update__error');
        if (
          e.response.data?.statusCode === 400 ||
          e.response.data?.statusCode === 502
        ) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
        setFormSubmitting(false);
      }
    } else {
      try {
        setForceNavigate(true);
        const { data: therapy } = await TherapyService.create({
          ...formData,
          name: formData.name.trim(),
        });
        setFormSubmitting(false);
        dispatch(therapyActions.clearSearch());
        queryClient.invalidateQueries([ReactQueryKeys.THERAPIES]);
        toast.success(t('therapies.messages.create__success'));
        navigate(`/therapies/${therapy.id}`);
      } catch (e: any) {
        let message = t('therapies.messages.create__error');
        if (
          e.response.data?.statusCode === 400 ||
          e.response.data?.statusCode === 502
        ) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
        setForceNavigate(false);
        setFormSubmitting(false);
      }
    }
  };

  /**
   * Reset form state
   */
  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();

    if (params.id) {
      reset({ ...record });
    } else {
      reset({ ...initialFormData });
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        {!editing && <h3 className="mb-4">{t('therapies.labels.create')}</h3>}

        <form
          style={{ maxWidth: '600px', position: 'relative' }}
          autoComplete="off"
          onSubmit={handleSubmit((data) => handleSubmitData(data))}
          className={formLoading || formSubmitting ? 'opacity-50' : ''}
          data-testid="form"
        >
          {formLoading && <Spinner isAbsolute />}
          <div className="pb-5">
            <label htmlFor="therapyName" className="form-label">
              {t('therapies.name')} *
            </label>

            {!editing || (editing && editingName) ? (
              <input
                disabled={!canManage}
                type="text"
                maxLength={20}
                autoFocus
                className="form-control form-control-lg"
                id="therapyName"
                data-testid="name"
                {...register('name', {
                  required: true,
                  maxLength: 20,
                  pattern: /^[a-zA-Z]+.*$/,
                  validate: {
                    noEmptySpaces: (value) =>
                      !!value.length && /^[^\s]+(\s+[^\s]+)*$/.test(value),
                  },
                })}
              />
            ) : (
              <div>
                <span className="h4">{getValues('name')}</span>
                <IoIosCreate
                  className="therapy-form__edit-title"
                  onClick={() => setEditingName(true)}
                  data-tip
                  data-for="therapies-edit-tooltip"
                  data-testid="toggleEdit"
                />
                <ReactTooltip
                  id="therapies-edit-tooltip"
                  effect="solid"
                  place="top"
                >
                  Edit Therapy name
                </ReactTooltip>
              </div>
            )}

            {errors?.name?.type === 'required' && (
              <div className="invalid-feedback pt-1">
                {t('therapies.errors.name__required')}
              </div>
            )}
            {errors?.name?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">
                {t('therapies.errors.name__max_characters')}
              </div>
            )}
            {errors?.name?.type === 'pattern' && (
              <div className="invalid-feedback pt-1">
                {t('therapies.errors.name__start_alphabetic')}
              </div>
            )}
            {errors?.name?.type === 'noEmptySpaces' && (
              <div className="invalid-feedback pt-1">
                {t('therapies.errors.name__no_empty_spaces')}
              </div>
            )}
          </div>

          {canManage ? (
            <>
              <Button
                loading={formSubmitting}
                defaultLabel={t('buttons.save')}
                loadingLabel={
                  params.id ? t('buttons.updating') : t('buttons.saving')
                }
                type="submit"
                disabled={formSubmitting || formLoading || !isDirty}
                data-testid="submitBtn"
              ></Button>

              <a
                href="/#"
                className={`btn btn-lg rounded btn-secondary ${
                  formSubmitting || formLoading || !isDirty ? 'disabled' : ''
                }`}
                onClick={handleCancel}
                data-testid="delete"
              >
                {t('buttons.cancel')}
              </a>
            </>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default TherapyForm;
