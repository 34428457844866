import { FC, useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import { IoIosAdd, IoMdTrash } from 'react-icons/io';
import DebouncedInput from 'components/UI/DebouncedInput/DebouncedInput';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ListDrug } from 'interfaces/list-drug';
import { drugActions } from 'store/slices/drug';
import { ListDrugResponse } from 'services/DrugService';
import ListPagination from 'components/UI/ListPagination/ListPagination';
import { PaginationState } from '@tanstack/react-table';
import ReactTooltip from 'react-tooltip';

type DrugListProps = {
  drugsResponse: ListDrugResponse | undefined;
  updateFilter: Function;
  onDelete: Function;
  canManage: boolean;
  setPagination: Function;
  pagination: PaginationState;
  refetch: boolean;
};

const DrugList: FC<DrugListProps> = ({
  drugsResponse,
  updateFilter,
  onDelete,
  canManage,
  setPagination,
  pagination,
  refetch,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const clearSearch: boolean = useSelector(
    (state: any) => state.drug.clearSearch,
  );

  const pageIndex = useSelector((state: any) => state.drug.pageIndex);

  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const isAdding = location.pathname.includes('/add');
    if (!params.id && drugsResponse?.records.length && !isAdding && !refetch) {
      navigate(`/drugs/${drugsResponse.records[0].id}`);
    }
  }, [drugsResponse, params.id, refetch, location.pathname, navigate]);

  const handleAddClick = () => {
    if (!drugsResponse?.records) return;

    const drugExists = drugsResponse.records.find(
      (drug: ListDrug) =>
        drug.name.toLowerCase().trim() === search.toLowerCase().trim(),
    );

    if (drugExists) {
      toast.error(t('drugs.messages.name__exists', { name: drugExists.name }));
      return false;
    }

    navigate({
      pathname: '/drugs/add',
      search: `?name=${search}`,
    });
  };

  useEffect(() => {
    if (clearSearch) {
      setSearch('');
      updateFilter('');
      dispatch(drugActions.resetClearSearch());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSearch]);

  useEffect(() => {
    setPagination({
      ...pagination,
      pageIndex,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  return (
    <>
      <div className="master-list__search-wrapper">
        <DebouncedInput
          placeholder={t('drugs.search_box')}
          maxLength={20}
          value={search}
          type="text"
          onChange={(value) => {
            setSearch(value as string);
            updateFilter(value);
          }}
          className="form-control form-control rounded mb-2"
          data-testid="search_drug_list_input"
        />

        {canManage ? (
          <>
            <button
              className="btn-rounded btn-rounded-sm master-list__button-add"
              onClick={handleAddClick}
              data-testid="add"
              data-tip
              data-for="drug-add-tooltip"
            >
              <IoIosAdd size={40} />
            </button>
            <ReactTooltip id="drug-add-tooltip" effect="solid" place="top">
              Add Drug
            </ReactTooltip>
          </>
        ) : null}
      </div>

      {drugsResponse?.records.length === 0 && (
        <div className="alert mb-0">{t('messages.no_records')}</div>
      )}

      {drugsResponse && drugsResponse.records.length > 0 ? (
        <div
          className="master-list master-list__no-scroll"
          key={'drug-response-records'}
        >
          {drugsResponse.records.map((drug: ListDrug) => {
            return (
              <NavLink
                to={`/drugs/${drug.id}`}
                className="master-list__item"
                key={drug.id}
              >
                {drug.name}

                {canManage && (
                  <>
                    <button
                      className="master-list__item-delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onDelete(drug);
                      }}
                      data-testid="delete"
                      data-tip
                      data-for="drug-delete-tooltip"
                    >
                      <IoMdTrash size={26} />
                    </button>
                    <ReactTooltip
                      id="drug-delete-tooltip"
                      effect="solid"
                      place="top"
                    >
                      Delete Drug
                    </ReactTooltip>
                  </>
                )}
              </NavLink>
            );
          })}
          <ListPagination
            canGetNextPage={
              Number(drugsResponse.pageIndex + 1) <
              Number(drugsResponse.totalPages)
            }
            canGetPreviousPage={Number(drugsResponse.pageIndex) > 0}
            nextPage={() => {
              setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex + 1,
              });
            }}
            previousPage={() => {
              setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex - 1,
              });
            }}
            pageCount={drugsResponse.totalPages}
            pageIndex={pagination.pageIndex}
            pageSize={pagination.pageSize}
            setPageSize={(pageSize: number) =>
              setPagination({
                ...pagination,
                pageSize,
              })
            }
            setPageIndex={(pageIndex: number) =>
              setPagination({
                ...pagination,
                pageIndex,
              })
            }
            hidePageSize
          />
        </div>
      ) : null}
    </>
  );
};

export default DrugList;
