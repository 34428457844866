import { useEffect, useState } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { useDispatch, useSelector } from 'react-redux';
import cookies from './config/cookie';
import { JWT_ACCESS_TOKEN } from './constants/jwt-tokens';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';
import { RolesEnum } from './enums';
import { AuthInitialState, authActions, selectUser } from './store/slices/auth';
import RequireAuth from './components/guards/RequireAuth';

// Services
import AuthService from './services/AuthService';

// Components
import AdminLayout from './components/UI/Layout/AdminLayout/AdminLayout';
import ModalSidebar from './components/UI/ModalSidebar/ModalSidebar';
import Spinner from './components/UI/Spinner/Spinner';
import ToasterNotifications from './components/UI/ToasterNotifications/ToasterNotifications';

// Pages
import Login from './pages/Login/Login';
import Facilities from './pages/Facilities/Facilities';
import NotFound from './pages/NotFound/NotFound';
import Companies from './pages/Companies/Companies';
import Users from './pages/Users/Users';
import CompaniesModalForm from './pages/Companies/CompaniesModalForm/CompaniesModalForm';
import UsersModalForm from './pages/Users/UsersModalForm/UsersModalForm';
import Therapies from './pages/Therapies/Therapies';
import Drugs from './pages/Drugs/Drugs';
import Advisories from './pages/Advisories/Advisories';
import CareAreas from './pages/CareAreas/CareAreas';
import Device from './pages/Device/Device';
import Reports from './pages/Reports/Reports';
import Deployment from './pages/Deployment/Deployment';
import FacilityForm from './pages/Facilities/FacilityForm/FacilityForm';
import intersection from 'lodash.intersection';
import Profile from './pages/Profile/Profile';
import CareAreaForm from './pages/CareAreas/CareAreaForm/CareAreaForm';
import DrugForm from './pages/Drugs/DrugForm/DrugForm';
import AdvisoryForm from './pages/Advisories/AdvisoryForm/AdvisoryForm';
import TherapyForm from './pages/Therapies/TherapyForm/TherapyForm';
import JsonComparisonModal from './pages/Deployment/CompareJsonFileModalForm/JsonComparisonModal';
import ManageBasicModeAdvisoryModalForm from 'pages/Advisories/ManageBasicModeAdvisoryModalForm/ManageBasicModeAdvisoryModalForm';
import UpdateDeploymentModal from 'pages/Deployment/DeploymentHistory/UpdateDeploymentModalForm/UpdateDeploymentModal';
import AdvisoryBasicModeModalForm from 'pages/Advisories/AdvisoryBasicModeModalForm/AdvisoryBasicModeModalForm';
import UpdatePartialDeploymentModal from 'pages/Deployment/PartialDeployment/UpdatePartialDeploymentModal';
import ModalSidebarWrapper from 'pages/Deployment/PartialDeployment/ModalSidebarWrapper';
import DrugReport from 'pages/Reports/DrugReport/DrugReport';
import { Help } from 'pages/Help/Help';
import { ADMINISTRATION, AUTHOR, MOBILE } from './constants/roles'; //REVIEWER
import {
  DEPLOYMENT_UPDATE_PARTIAL_PREFIX,
  DEPLOYMENT_ID_PARAMS_KEY,
  DEPLOYMENT_UPDATE_PARTIAL_PARAMS_KEY,
  DEPLOYMENT_JSON_PREFIX,
  FRONT_ROUTER,
  DEPLOYMENT_UPDATE_PREFIX,
} from 'constants/router';
import { useQueryClient } from '@tanstack/react-query';
import { signOutUser } from 'helpers/signOut';

type AppRoutesProps = Pick<AuthInitialState, 'user'>;

const AppRoutes = ({ user }: AppRoutesProps): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              {user ? <AdminLayout /> : <Spinner isAbsolute />}
            </RequireAuth>
          }
        >
          {!!user && user?.roles?.includes(RolesEnum.SUPER_ADMIN) && (
            <>
              <Route index element={<Navigate to="/companies" />} />
              <Route path="/companies" element={<Companies />}>
                <Route
                  path=":id"
                  element={
                    <ModalSidebar title="companies.messages.modal__edit__title">
                      <CompaniesModalForm />
                    </ModalSidebar>
                  }
                />
                <Route
                  path="add"
                  element={
                    <ModalSidebar title="companies.messages.modal__add__title">
                      <CompaniesModalForm />
                    </ModalSidebar>
                  }
                />
              </Route>

              <Route path="/users" element={<Users />}>
                <Route
                  path=":id"
                  element={
                    <ModalSidebar title="users.messages.modal__edit__title">
                      <UsersModalForm />
                    </ModalSidebar>
                  }
                />
                <Route
                  path="add"
                  element={
                    <ModalSidebar title="users.messages.modal__add__title">
                      <UsersModalForm />
                    </ModalSidebar>
                  }
                />
              </Route>
              <Route path={FRONT_ROUTER.HELP} element={<Help />} />
            </>
          )}

          {!!user &&
            !!intersection(user?.roles, [ADMINISTRATION, AUTHOR]).length && ( //REVIEWER
              <>
                <Route index element={<Navigate to="/facilities" />} />
                <Route path="/facilities" element={<Facilities />}>
                  <Route path=":id" element={<FacilityForm />} />
                  {/* <Route path="add" element={<FacilityForm />} /> */}
                </Route>
                <Route path="/care-areas" element={<CareAreas />}>
                  <Route path=":id" element={<CareAreaForm />} />
                  <Route path="add" element={<CareAreaForm />} />
                </Route>
                <Route path="/drugs" element={<Drugs />}>
                  <Route path=":id" element={<DrugForm />} />
                  <Route path="add" element={<DrugForm />} />
                </Route>
                <Route path="/advisories" element={<Advisories />}>
                  <Route path=":id" element={<AdvisoryForm />}>
                    <Route
                      path="reasons/manage"
                      element={
                        <ModalSidebar title="advisories.messages.modal__manage_basic_mode__title">
                          <ManageBasicModeAdvisoryModalForm />
                        </ModalSidebar>
                      }
                    ></Route>
                    <Route
                      path="reasons/add"
                      element={
                        <ModalSidebar title="advisories.messages.modal__assign_basic_mode__title">
                          <AdvisoryBasicModeModalForm />
                        </ModalSidebar>
                      }
                    ></Route>
                    <Route
                      path="reasons/:reasonId"
                      element={
                        <ModalSidebar title="advisories.messages.modal__edit_basic_mode__title">
                          <AdvisoryBasicModeModalForm />
                        </ModalSidebar>
                      }
                    ></Route>
                  </Route>
                  <Route path="add" element={<AdvisoryForm />} />
                </Route>
                <Route path="/therapies" element={<Therapies />}>
                  <Route path=":id" element={<TherapyForm />} />
                  <Route path="add" element={<TherapyForm />} />
                </Route>
                <Route path="/device" element={<Device />}></Route>
                <Route path="/reports" element={<Reports />}>
                  <Route index element={<Navigate to="drug-report" />} />
                  <Route path="drug-report" element={<DrugReport />}></Route>
                </Route>
                <Route path="/settings/profile" element={<Profile />}></Route>
                <Route
                  path={FRONT_ROUTER.DEPLOYMENT_TABS}
                  element={<Deployment />}
                >
                  <Route
                    path={DEPLOYMENT_JSON_PREFIX}
                    element={
                      <ModalSidebar
                        title="deployment.form.modal__compare__json"
                        fullWidth
                      >
                        <JsonComparisonModal />
                      </ModalSidebar>
                    }
                  />
                  <Route
                    path={`${DEPLOYMENT_UPDATE_PREFIX}/:${DEPLOYMENT_ID_PARAMS_KEY}`}
                    element={
                      <ModalSidebar title="deployment.form.modal__update__deployment">
                        <UpdateDeploymentModal />
                      </ModalSidebar>
                    }
                  />

                  <Route
                    path={`${DEPLOYMENT_UPDATE_PARTIAL_PREFIX}/:${DEPLOYMENT_UPDATE_PARTIAL_PARAMS_KEY}`}
                    element={
                      <ModalSidebarWrapper>
                        <UpdatePartialDeploymentModal />
                      </ModalSidebarWrapper>
                    }
                  />
                </Route>
                <Route path={FRONT_ROUTER.HELP} element={<Help />} />
              </>
            )}

          {!!user && !!intersection(user?.roles, [MOBILE]).length && (
            <>
              <Route index element={<Navigate to="/profile" />} />
              <Route path="/profile" element={<Profile />}></Route>
            </>
          )}
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
        <Route path={FRONT_ROUTER.ERROR} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

function App() {
  const dispatch = useDispatch();
  const [sessionChecked, setSessionChecked] = useState(false);
  const user = useSelector(selectUser);
  const queryClient = useQueryClient();
  useEffect(() => {
    // Check if the user is signed in and extract the jwt tokens
    const checkSession = async () => {
      try {
        // Get current authenticated Cognito user
        const authSession = await fetchAuthSession();
        if (authSession.tokens) {
          cookies.set(
            JWT_ACCESS_TOKEN,
            authSession.tokens?.idToken?.toString(),
            {
              path: '/',
            },
          );

          // Get the user and update the store
          const user = await AuthService.getMe();
          dispatch(
            authActions.updateUser({
              user: user.data,
            }),
          );
        }

        setSessionChecked(true);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (_e) {
        await signOutUser(dispatch, queryClient);
        setSessionChecked(true);
      }
    };

    checkSession();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Authenticator.Provider>
      {sessionChecked && <AppRoutes user={user} />}
      <ToasterNotifications />
    </Authenticator.Provider>
  );
}

export default App;
