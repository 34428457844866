import { Facility } from 'interfaces/facility';
import { FC, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
// import { IoIosAdd, IoMdTrash } from 'react-icons/io';
// import DebouncedInput from 'components/UI/DebouncedInput/DebouncedInput';
// import { toast } from 'react-hot-toast';
// import ListPagination from 'components/UI/ListPagination/ListPagination';
// import { PaginationState } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { facilityActions } from 'store/slices/facility';
import { FacilityResponse } from 'services/FacilityService';

type FacilityListProps = {
  facilityResponse: FacilityResponse | undefined;
  updateFilter: Function;
  // onDelete: Function;
  // canManage: boolean;
  // setPagination: Function;
  // pagination: PaginationState;
  refetch: boolean;
};

const FacilityList: FC<FacilityListProps> = ({
  facilityResponse,
  updateFilter,
  refetch,
  // onDelete,
  // canManage,
  // setPagination,
  // pagination,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const clearSearch: boolean = useSelector(
    (state: any) => state.facility.clearSearch,
  );
  const dispatch = useDispatch();
  // const [search, setSearch] = useState<string>('');

  // const handleAddClick = () => {
  //   if (!facilityResponse?.records) return;

  //   const facilityExists = facilityResponse?.records.find(
  //     (facility: Facility) =>
  //       facility.name.toLowerCase().trim() === search.toLowerCase().trim()
  //   );

  //   if (facilityExists) {
  //     toast.error(
  //       t('facilities.messages.name__exists', { name: facilityExists.name })
  //     );
  //     return false;
  //   }

  //   navigate({
  //     pathname: '/facilities/add',
  //     search: `?name=${search}`,
  //   });
  // };

  useEffect(() => {
    if (!params.id && facilityResponse?.records.length && !refetch) {
      navigate(`/facilities/${facilityResponse.records[0].id}`);
    }
  }, [facilityResponse, refetch, params.id, navigate]);

  useEffect(() => {
    if (clearSearch) {
      // setSearch('');
      updateFilter('');
      dispatch(facilityActions.resetClearSearch());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSearch]);

  return (
    <>
      <div className="master-list__search-wrapper">
        {/* <DebouncedInput
          placeholder={t('facilities.search_box')}
          value={search}
          type="text"
          onChange={(value) => {
            setSearch(value as string);
            updateFilter(value);
          }}
          className="form-control form-control rounded mb-2"
        /> */}

        {/* {canManage ? (
          <button
            className="btn-rounded btn-rounded-sm master-list__button-add"
            onClick={handleAddClick}
            data-testid="add"
          >
            <IoIosAdd size={40} />
          </button>
        ) : null} */}
      </div>

      {facilityResponse?.records.length === 0 && (
        <div className="alert mb-0">{t('messages.no_records')}</div>
      )}

      {facilityResponse && facilityResponse?.records.length > 0 && (
        <div className="master-list master-list__no-scroll">
          {facilityResponse.records.map((facility: Facility) => {
            return (
              <NavLink
                to={`/facilities/${facility.id}`}
                className="master-list__item"
                key={facility.id}
              >
                {facility.name}

                {/* {canManage && (
                  <button
                    className="master-list__item-delete"
                           onClick={(e) => {
                        e.stopPropagation();
                        onDelete(facility);
                      }}
                    data-testid="delete"
                  >
                    <IoMdTrash size={26} />
                  </button>
                )} */}
              </NavLink>
            );
          })}
          {/* <ListPagination
            canGetNextPage={
              Number(facilityResponse.pageIndex + 1) <
              Number(facilityResponse.totalPages)
            }
            canGetPreviousPage={Number(facilityResponse.pageIndex) > 0}
            nextPage={() => {
              setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex + 1,
              });
            }}
            previousPage={() => {
              setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex - 1,
              });
            }}
            pageCount={facilityResponse.totalPages}
            pageIndex={pagination.pageIndex}
            pageSize={pagination.pageSize}
            setPageSize={(pageSize: number) =>
              setPagination({
                ...pagination,
                pageSize,
              })
            }
            setPageIndex={(pageIndex: number) =>
              setPagination({
                ...pagination,
                pageIndex,
              })
            }
            hidePageSize
          /> */}
        </div>
      )}
    </>
  );
};

export default FacilityList;
