import {
  Controller,
  Control,
  UseFormGetValues,
  FieldErrors,
  UseFormGetFieldState,
} from 'react-hook-form';
import { Concentration, ConcentrationFormData } from 'interfaces/concentration';
import { FC, useMemo } from 'react';
import InputMask from 'react-input-mask';
import isString from 'lodash.isstring';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { getDurationLimits } from 'helpers/concentrations';

const durationRegExp = /\d\d:\d\d/;
const DURATION_VALID_VALUE = '__:__';

export const timeToMinutes = (value: string) => {
  const hoursSeconds = value.split(':');
  return Number(hoursSeconds[0]) * 60 + Number(hoursSeconds[1]);
};

export const minutesToTime = (value: any): string => {
  if (!value) return '00:00';

  const hours = Math.floor(value / 60);
  const minutes = value % 60;

  const fullHours = hours >= 10 ? hours : `0${hours}`;
  const fullMinutes = minutes >= 10 ? minutes : `0${minutes}`;

  return `${fullHours}:${fullMinutes}`;
};

type DurationLimitsProps = {
  record: Concentration;
  control: Control<ConcentrationFormData, any>;
  getValues: UseFormGetValues<ConcentrationFormData>;
  getFieldState: UseFormGetFieldState<any>;
  errors: FieldErrors<ConcentrationFormData>;
  watchDurationLHL: string | number | null | undefined;
  watchDurationLSL: string | number | null | undefined;
  watchDurationUSL: string | number | null | undefined;
  watchDurationUHL: string | number | null | undefined;
  watchDoseModeType:
    | {
        label: string;
        value: string;
      }
    | null
    | undefined;
  canManage: boolean;
};

const DurationLimits: FC<DurationLimitsProps> = ({
  record,
  control,
  getValues,
  getFieldState,
  errors,
  watchDurationLHL,
  watchDurationLSL,
  watchDurationUSL,
  watchDurationUHL,
  watchDoseModeType,
  canManage,
}) => {
  const { t } = useTranslation();

  // Get Dose Mode Time values
  const durationLimits = useMemo(() => {
    return getDurationLimits(record, watchDoseModeType);
  }, [record, watchDoseModeType]);

  return (
    <div className="row pb-3 flex-nowrap">
      <div className="device-concentration-form__label">
        {t('device.duration')}
      </div>

      {/* Lower hard limit */}
      <div className="device-concentration__box-input">
        <Controller
          control={control}
          name="duration_lower_hard_limit"
          rules={{
            validate: {
              validDuration: (value) => {
                if (!value || value === DURATION_VALID_VALUE) return true;

                return isString(value) && durationRegExp.test(value);
              },
              oneLowerLimitRequired: (value) => {
                const { isTouched } = getFieldState(
                  'duration_lower_hard_limit',
                );

                if (
                  ((value === '' &&
                    watchDurationLSL === DURATION_VALID_VALUE) ||
                    (value === DURATION_VALID_VALUE &&
                      watchDurationLSL === '')) &&
                  !isTouched
                )
                  return true;

                return (
                  (isString(value) && durationRegExp.test(value)) ||
                  (isString(watchDurationLSL) &&
                    durationRegExp.test(watchDurationLSL))
                );
              },
              maximumAllowedHours: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                return Number(value.split(':')[0]) <= 24;
              },
              maximumAllowedMinutes: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                return Number(value.split(':')[1]) <= 59;
              },
              minLimit: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                const minutes = timeToMinutes(value);

                return minutes >= durationLimits.min;
              },
              maxLimit: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                const minutes = timeToMinutes(value);

                return minutes <= durationLimits.max;
              },
              increasingOrder: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                const currentMinutes = timeToMinutes(value);
                let isValid = true;

                const [LSL, USL, UHL] = getValues([
                  'duration_lower_soft_limit',
                  'duration_upper_soft_limit',
                  'duration_upper_hard_limit',
                ]);

                isValid =
                  isValid &&
                  (isString(LSL) && durationRegExp.test(LSL)
                    ? timeToMinutes(LSL) > currentMinutes
                    : true);
                isValid =
                  isValid &&
                  (isString(USL) && durationRegExp.test(USL)
                    ? timeToMinutes(USL) > currentMinutes
                    : true);
                isValid =
                  isValid &&
                  (isString(UHL) && durationRegExp.test(UHL)
                    ? timeToMinutes(UHL) > currentMinutes
                    : true);

                return isValid;
              },
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputMask
              value={value as any}
              onChange={onChange}
              onBlur={onBlur}
              inputRef={ref}
              className={
                'form-control form-control-lg rounded-1 text-center device-concentration__input--hard-limit ' +
                (errors?.duration_lower_hard_limit ? 'has-error' : '')
              }
              maskChar={'_'}
              mask={'99:99'}
              placeholder="hh:mm"
              inputMode="numeric"
              data-tip
              data-for="duration_lower_hard_limit"
              data-testid="duration_lower_hard_limit"
              disabled={!canManage}
            />
          )}
        />

        {errors?.duration_lower_hard_limit && (
          <ReactTooltip
            id="duration_lower_hard_limit"
            effect="solid"
            place="bottom"
            delayHide={300}
            type="error"
            aria-label="borrar"
          >
            {errors?.duration_lower_hard_limit?.type === 'validDuration' &&
              t('device.errors.duration__invalid')}
            {errors?.duration_lower_hard_limit?.type ===
              'oneLowerLimitRequired' &&
              t('device.errors.one_lower_limit__required')}
            {errors?.duration_lower_hard_limit?.type === 'increasingOrder' &&
              t('device.errors.order_LHL_LSL_USL_UHL')}
            {errors?.duration_lower_hard_limit?.type ===
              'maximumAllowedHours' && t('device.errors.maximum_allowed_hours')}
            {errors?.duration_lower_hard_limit?.type ===
              'maximumAllowedMinutes' &&
              t('device.errors.maximum_allowed_minutes')}
            {errors?.duration_lower_hard_limit?.type === 'minLimit' &&
              t('device.errors.not_lower_than', {
                value: minutesToTime(durationLimits.min),
              })}
            {errors?.duration_lower_hard_limit?.type === 'maxLimit' &&
              t('device.errors.not_greater_than', {
                value: minutesToTime(durationLimits.max),
              })}
          </ReactTooltip>
        )}
      </div>

      {/* Lower soft limit */}
      <div className="device-concentration__box-input">
        <Controller
          control={control}
          name="duration_lower_soft_limit"
          rules={{
            validate: {
              validDuration: (value) => {
                if (!value || value === DURATION_VALID_VALUE) return true;

                return isString(value) && durationRegExp.test(value);
              },
              oneLowerLimitRequired: (value) => {
                const { isTouched } = getFieldState(
                  'duration_lower_soft_limit',
                );

                if (
                  ((value === '' &&
                    watchDurationLHL === DURATION_VALID_VALUE) ||
                    (value === DURATION_VALID_VALUE &&
                      watchDurationLHL === '')) &&
                  !isTouched
                )
                  return true;

                return (
                  (isString(value) && durationRegExp.test(value)) ||
                  (isString(watchDurationLHL) &&
                    durationRegExp.test(watchDurationLHL))
                );
              },
              maximumAllowedHours: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                return Number(value.split(':')[0]) <= 24;
              },
              maximumAllowedMinutes: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                return Number(value.split(':')[1]) <= 59;
              },
              minLimit: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                const minutes = timeToMinutes(value);

                return minutes >= durationLimits.min;
              },
              maxLimit: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                const minutes = timeToMinutes(value);

                return minutes <= durationLimits.max;
              },
              increasingOrder: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                const currentMinutes = timeToMinutes(value);
                let isValid = true;

                const [LHL, USL, UHL] = getValues([
                  'duration_lower_hard_limit',
                  'duration_upper_soft_limit',
                  'duration_upper_hard_limit',
                ]);

                isValid =
                  isValid &&
                  (isString(LHL) && durationRegExp.test(LHL)
                    ? timeToMinutes(LHL) < currentMinutes
                    : true);
                isValid =
                  isValid &&
                  (isString(USL) && durationRegExp.test(USL)
                    ? timeToMinutes(USL) > currentMinutes
                    : true);
                isValid =
                  isValid &&
                  (isString(UHL) && durationRegExp.test(UHL)
                    ? timeToMinutes(UHL) > currentMinutes
                    : true);

                return isValid;
              },
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputMask
              value={value as any}
              onChange={onChange}
              onBlur={onBlur}
              inputRef={ref}
              className={
                'form-control form-control-lg rounded-1 text-center device-concentration__input--soft-limit ' +
                (errors?.duration_lower_soft_limit ? 'has-error' : '')
              }
              maskChar={'_'}
              mask={'99:99'}
              placeholder="hh:mm"
              inputMode="numeric"
              data-tip
              data-for="duration_lower_soft_limit"
              data-testid="duration_lower_soft_limit"
              disabled={!canManage}
            />
          )}
        />

        {errors?.duration_lower_soft_limit && (
          <ReactTooltip
            id="duration_lower_soft_limit"
            effect="solid"
            place="bottom"
            delayHide={300}
            type="error"
          >
            {errors?.duration_lower_soft_limit?.type === 'validDuration' &&
              t('device.errors.duration__invalid')}
            {errors?.duration_lower_soft_limit?.type ===
              'oneLowerLimitRequired' &&
              t('device.errors.one_lower_limit__required')}
            {errors?.duration_lower_soft_limit?.type === 'increasingOrder' &&
              t('device.errors.order_LHL_LSL_USL_UHL')}
            {errors?.duration_lower_soft_limit?.type ===
              'maximumAllowedHours' && t('device.errors.maximum_allowed_hours')}
            {errors?.duration_lower_soft_limit?.type ===
              'maximumAllowedMinutes' &&
              t('device.errors.maximum_allowed_minutes')}
            {errors?.duration_lower_soft_limit?.type === 'minLimit' &&
              t('device.errors.not_lower_than', {
                value: minutesToTime(durationLimits.min),
              })}
            {errors?.duration_lower_soft_limit?.type === 'maxLimit' &&
              t('device.errors.not_greater_than', {
                value: minutesToTime(durationLimits.max),
              })}
          </ReactTooltip>
        )}
      </div>

      {/* Upper soft limit */}
      <div className="device-concentration__box-input">
        <Controller
          control={control}
          name="duration_upper_soft_limit"
          rules={{
            validate: {
              validDuration: (value) => {
                if (!value || value === DURATION_VALID_VALUE) return true;

                return isString(value) && durationRegExp.test(value);
              },
              oneUpperLimitRequired: (value) => {
                const { isTouched } = getFieldState(
                  'duration_upper_soft_limit',
                );

                if (
                  ((value === '' &&
                    watchDurationUHL === DURATION_VALID_VALUE) ||
                    (value === DURATION_VALID_VALUE &&
                      watchDurationUHL === '')) &&
                  !isTouched
                )
                  return true;

                return (
                  (isString(value) && durationRegExp.test(value)) ||
                  (isString(watchDurationUHL) &&
                    durationRegExp.test(watchDurationUHL))
                );
              },
              maximumAllowedHours: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                return Number(value.split(':')[0]) <= 24;
              },
              maximumAllowedMinutes: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                return Number(value.split(':')[1]) <= 59;
              },
              minLimit: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                const minutes = timeToMinutes(value);

                return minutes >= durationLimits.min;
              },
              maxLimit: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                const minutes = timeToMinutes(value);

                return minutes <= durationLimits.max;
              },
              increasingOrder: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                const currentMinutes = timeToMinutes(value);
                let isValid = true;

                const [LHL, LSL, UHL] = getValues([
                  'duration_lower_hard_limit',
                  'duration_lower_soft_limit',
                  'duration_upper_hard_limit',
                ]);

                isValid =
                  isValid &&
                  (isString(LHL) && durationRegExp.test(LHL)
                    ? timeToMinutes(LHL) < currentMinutes
                    : true);
                isValid =
                  isValid &&
                  (isString(LSL) && durationRegExp.test(LSL)
                    ? timeToMinutes(LSL) < currentMinutes
                    : true);
                isValid =
                  isValid &&
                  (isString(UHL) && durationRegExp.test(UHL)
                    ? timeToMinutes(UHL) > currentMinutes
                    : true);

                return isValid;
              },
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputMask
              value={value as any}
              onChange={onChange}
              onBlur={onBlur}
              inputRef={ref}
              className={
                'form-control form-control-lg rounded-1 text-center device-concentration__input--soft-limit ' +
                (errors?.duration_upper_soft_limit ? 'has-error' : '')
              }
              maskChar={'_'}
              mask={'99:99'}
              placeholder="hh:mm"
              inputMode="numeric"
              data-tip
              data-for="duration_upper_soft_limit"
              data-testid="duration_upper_soft_limit"
              disabled={!canManage}
            />
          )}
        />
        {errors?.duration_upper_soft_limit && (
          <ReactTooltip
            id="duration_upper_soft_limit"
            effect="solid"
            place="bottom"
            delayHide={300}
            type="error"
          >
            {errors?.duration_upper_soft_limit?.type === 'validDuration' &&
              t('device.errors.duration__invalid')}
            {errors?.duration_upper_soft_limit?.type ===
              'oneUpperLimitRequired' &&
              t('device.errors.one_upper_limit__required')}
            {errors?.duration_upper_soft_limit?.type === 'increasingOrder' &&
              t('device.errors.order_LHL_LSL_USL_UHL')}
            {errors?.duration_upper_soft_limit?.type ===
              'maximumAllowedHours' && t('device.errors.maximum_allowed_hours')}
            {errors?.duration_upper_soft_limit?.type ===
              'maximumAllowedMinutes' &&
              t('device.errors.maximum_allowed_minutes')}
            {errors?.duration_upper_soft_limit?.type === 'minLimit' &&
              t('device.errors.not_lower_than', {
                value: minutesToTime(durationLimits.min),
              })}
            {errors?.duration_upper_soft_limit?.type === 'maxLimit' &&
              t('device.errors.not_greater_than', {
                value: minutesToTime(durationLimits.max),
              })}
          </ReactTooltip>
        )}
      </div>

      {/* Upper hard limit */}
      <div className="device-concentration__box-input">
        <Controller
          control={control}
          name="duration_upper_hard_limit"
          rules={{
            validate: {
              validDuration: (value) => {
                if (!value || value === DURATION_VALID_VALUE) return true;

                return isString(value) && durationRegExp.test(value);
              },
              oneUpperLimitRequired: (value) => {
                const { isTouched } = getFieldState(
                  'duration_upper_hard_limit',
                );

                if (
                  ((value === '' &&
                    watchDurationUSL === DURATION_VALID_VALUE) ||
                    (value === DURATION_VALID_VALUE &&
                      watchDurationUSL === '')) &&
                  !isTouched
                )
                  return true;

                return (
                  (isString(value) && durationRegExp.test(value)) ||
                  (isString(watchDurationUSL) &&
                    durationRegExp.test(watchDurationUSL))
                );
              },
              maximumAllowedHours: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                return Number(value.split(':')[0]) <= 24;
              },
              maximumAllowedMinutes: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                return Number(value.split(':')[1]) <= 59;
              },
              minLimit: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                const minutes = timeToMinutes(value);

                return minutes >= durationLimits.min;
              },
              maxLimit: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                const minutes = timeToMinutes(value);

                return minutes <= durationLimits.max;
              },
              increasingOrder: (value) => {
                if (!isString(value) || !durationRegExp.test(value))
                  return true;

                const currentMinutes = timeToMinutes(value);
                let isValid = true;

                const [LHL, LSL, USL] = getValues([
                  'duration_lower_hard_limit',
                  'duration_lower_soft_limit',
                  'duration_upper_soft_limit',
                ]);

                isValid =
                  isValid &&
                  (isString(LHL) && durationRegExp.test(LHL)
                    ? timeToMinutes(LHL) < currentMinutes
                    : true);
                isValid =
                  isValid &&
                  (isString(LSL) && durationRegExp.test(LSL)
                    ? timeToMinutes(LSL) < currentMinutes
                    : true);
                isValid =
                  isValid &&
                  (isString(USL) && durationRegExp.test(USL)
                    ? timeToMinutes(USL) < currentMinutes
                    : true);

                return isValid;
              },
            },
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputMask
              value={value as any}
              onChange={onChange}
              onBlur={onBlur}
              inputRef={ref}
              className={
                'form-control form-control-lg rounded-1 text-center device-concentration__input--hard-limit ' +
                (errors?.duration_upper_hard_limit ? 'has-error' : '')
              }
              maskChar={'_'}
              mask={'99:99'}
              placeholder="hh:mm"
              inputMode="numeric"
              data-tip
              data-for="duration_upper_hard_limit"
              data-testid="duration_upper_hard_limit"
              disabled={!canManage}
            />
          )}
        />
        {errors?.duration_upper_hard_limit && (
          <ReactTooltip
            id="duration_upper_hard_limit"
            effect="solid"
            place="bottom"
            delayHide={300}
            type="error"
          >
            {errors?.duration_upper_hard_limit?.type === 'validDuration' &&
              t('device.errors.duration__invalid')}
            {errors?.duration_upper_hard_limit?.type ===
              'oneUpperLimitRequired' &&
              t('device.errors.one_upper_limit__required')}
            {errors?.duration_upper_hard_limit?.type === 'increasingOrder' &&
              t('device.errors.order_LHL_LSL_USL_UHL')}
            {errors?.duration_upper_hard_limit?.type ===
              'maximumAllowedHours' && t('device.errors.maximum_allowed_hours')}
            {errors?.duration_upper_hard_limit?.type ===
              'maximumAllowedMinutes' &&
              t('device.errors.maximum_allowed_minutes')}
            {errors?.duration_upper_hard_limit?.type === 'minLimit' &&
              t('device.errors.not_lower_than', {
                value: minutesToTime(durationLimits.min),
              })}
            {errors?.duration_upper_hard_limit?.type === 'maxLimit' &&
              t('device.errors.not_greater_than', {
                value: minutesToTime(durationLimits.max),
              })}
          </ReactTooltip>
        )}
      </div>

      {/* Units */}
      <div className="device-concentration-units">hr : min</div>
    </div>
  );
};

export default DurationLimits;
